import React from "react"

export default function DownloadIcon(props) {
  return (
    <svg {...props} width="37" height="37" viewBox="0 0 37 37" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.6719 22.7241C17.9412 22.9933 18.3777 22.9933 18.6469 22.7241L23.0347 18.3364C23.3039 18.0671 23.3039 17.6306 23.0347 17.3613C22.7654 17.0921 22.3289 17.0921 22.0596 17.3613L18.1594 21.2615L14.2592 17.3613C13.99 17.0921 13.5534 17.0921 13.2842 17.3613C13.0149 17.6306 13.0149 18.0671 13.2842 18.3364L17.6719 22.7241ZM17.47 8.44727L17.47 22.2366L18.8489 22.2366L18.8489 8.44727L17.47 8.44727Z" />
      <line x1="10.6256" y1="26.255" x2="25.9411" y2="26.255" strokeWidth="1.83857" strokeLinecap="round" />
    </svg>
  )
}
