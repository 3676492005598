import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ConfirmDialog from "./ConfirmDialog"

import { useStore } from "../hooks/store"
import trackEvent from "../utils/track-event"

import blackIcon from "../images/svt_audiodesc_black.svg"
import whiteIcon from "../images/svt_audiodesc_white.svg"

const ToggleButton = styled.button`
  display: block;
  width: 2.125rem;
  height: 2.125rem;
  margin-right: 0.875rem;
  padding: 0;
  border: 0;
  border-radius: 0.3125rem;
  background-color: ${props => props.isActive ? "white" : "transparent"};
  background-image: url(${props => props.isActive ? blackIcon : whiteIcon});
  background-position: center;
  background-repeat: no-repeat;
  background-size: 80%;
  transition: all 0.3s ease;
`

const StyledConfirmDialog = styled(ConfirmDialog)`
  padding-top: 3.9375rem;
  background: white url(${blackIcon}) no-repeat center 1.25rem;
  background-size: 1.625rem;
`

export default function AudioDescriptionButton({ showConfirm, onSetConfirm }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const dialogRef = createRef()

  const track = (value) => {
    trackEvent({ category: "Settings", action: value ? "Activate" : "Deactivate", name: "Audio Description" })
  }

  const activateAudioDesc = () => {
    onSetConfirm(false)
    updateStore((store) => {
      store.audioDescriptionEnabled = true
    })
    track(true)
  }

  useEffect(() => {
    if (showConfirm && dialogRef.current) {
      dialogRef.current.focus()
    }
  }, [showConfirm])

  return (
    <div>
      <ToggleButton
        isActive={store.audioDescriptionEnabled}
        onClick={() => {
          if (!store.audioDescriptionEnabled) {
            onSetConfirm(true)
          } else {
            updateStore((store) => {
              store.audioDescriptionEnabled = false
            })
            track(false)
          }
        }}
      >
        <span className="structural">
          {intl.formatMessage({ id: `turn${store.audioDescriptionEnabled ? "Off" : "On"}AudioDescription` })}
        </span>
      </ToggleButton>
      {showConfirm &&
        <StyledConfirmDialog
          heading={`${intl.formatMessage({ id: "activateAudioDescription" })}?`}
          message={`${intl.formatMessage({ id: "audioDescriptionConfirmMessage" })}.`}
          confirmButtonLabel={intl.formatMessage({ id: "yesActivate" })}
          onCancel={() => onSetConfirm(false)}
          onConfirm={activateAudioDesc}
        />
      }
    </div>
  )
}
