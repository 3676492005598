import React from "react"
import styled from "styled-components"

const Wrapper = styled.section`
  @media (orientation: landscape) and (min-width: 500px) {
    margin-bottom: 1.5rem;
  }

  & > h1,
  & > h2,
  & > h3,
  & > h4,
  & > h5,
  & > h6,
  & > p {
    padding-left: 1rem;
  }

  h1, h2, h3, h4 {
    margin-top: 0;
  }

  h2 {
    font-family: ${props => props.theme.headingFont};
    font-size: 1.2rem;

    @media (min-width: 768px) and (min-height: 768px) {
      font-size: 1.32rem;
    }

    @media (min-width: 895px) {
      margin-right: 1.5rem;
    }
  }
`

export default function RoomContentSection({ heading, children }) {
  return (
    <Wrapper>
      {heading && <h2 className="title">{heading}</h2>}
      {children}
    </Wrapper>
  )
}
