import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ModalWrapper from "./ModalWrapper"

import { useModal } from "../../hooks/modal"

import closeIcon from "../../images/close-plaster.svg"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  animation: hv-modal-slide-up 0.3s forwards;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
  }

  @keyframes hv-modal-slide-up {
    0%    { transform: translateY(100%); }
    100%  { transform: translateY(0); }
  }
`

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.78125rem;
  right: 0.625rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  padding: 0;
  background: none;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  position: absolute;
  top: 4.0625rem;
  left: 50%;
  width: calc(100% - 2.5rem);
  max-width: 40rem;
  height: calc(100% - 6rem);
  border-style: solid;
  border-color: white;
  border-radius: 0.625rem;
  color: white;
  background: ${props => props.background};
  background-size: cover;
  transform: translateX(-50%);

  h2 {
    margin: 0 0 0.75rem;
    font: 1.5rem/1 ${props => props.theme.headingFont};
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: ${props => props.hasLogo ? 3.125 : 1.625}rem 1rem 1rem;
  overflow: auto;
`

const Logo = styled.div`
  position: absolute;
  top: -3.7rem;
  left: -1rem;
  z-index: 2;
  width: 5.875rem;
  height: 5.875rem;
  border: 0.3125rem solid white;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
`

export default function HvModalWrapper({
  background,
  logo,
  logoAltText = "",
  borderWidth = "0.3125rem",
  scrollReset,
  overlay = null,
  children,
  subContent = null,
  ...props
}) {
  const intl = useIntl()
  const ref = createRef()
  const { closeModal } = useModal()

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = 0
    }
  }, [scrollReset])

  return (
    <ModalWrapper standardUi={false} {...props}>
      <Wrapper>
        <Bg onClick={closeModal} />
        <CloseButton onClick={closeModal} aria-label={intl.formatMessage({ id: "close" })}>
          <img src={closeIcon} alt="" role="presentation" />
        </CloseButton>
        <Content style={{ borderWidth }} background={background}>
          {logo &&
            <Logo style={{ backgroundImage: `url(${logo}?nf_resize=fit&w=400)` }}>
              {logoAltText && <span className="structural">{logoAltText}</span>}
            </Logo>
          }
          {overlay}
          <Inner ref={ref} hasLogo={!!logo}>
            {children}
          </Inner>
        </Content>
      </Wrapper>
      {subContent}
    </ModalWrapper>
  )
}
