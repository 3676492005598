import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import playlistIcon from "../images/playlist-icon.svg"

const Button = styled.button`
  height: 1.4375rem;
  padding: 0 1.8125rem 0 0;
  border: 0;
  background: url(${playlistIcon}) no-repeat right -1px;
  background-size: 1.6875rem 1.6875rem;
`

const Label = styled.span`
  font-size: 0.625rem;
  color: #666;
  white-space: nowrap;

  @media (max-width: 359px) {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
  }
`

const PlayAllButton = ({ onClick, ...props }) => {
  const intl = useIntl()

  return (
    <Button onClick={onClick} {...props}>
      <Label>{intl.formatMessage({ id: "playAll" })}</Label>
    </Button>
  )
}

export default PlayAllButton
