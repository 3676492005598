import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ModalWrapper from "./ModalWrapper"
import ActionButtonSvg from "../ActionButtonSvg"
import FlipCard from "../FlipCard"
import CloseIcon from "../icons/Close"

import { useModal } from "../../hooks/modal"

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  animation: flip-card-bg-fade-in 0.3s forwards;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(5px);
  }

  &.close {
    animation: flip-card-bg-fade-out 0.3s forwards;
  }

  @keyframes flip-card-bg-fade-in {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes flip-card-bg-fade-out {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Inner = styled.div`
  position: absolute;
  top: 2.5rem;
  left: 0;
  right: 0;
  width: 21.8125rem;
  max-width: calc(100% - 1rem);
  height: 31.75rem;
  margin: auto;
  animation: flip-card-slide-up 0.3s forwards;

  &.close {
    animation: flip-card-slide-down 0.3s forwards;
  }

  @keyframes flip-card-slide-up {
    0%   { transform: translateY(calc(50vh + 100%)); }
    100% { transform: translateY(0); }
  }

  @keyframes flip-card-slide-down {
    0%   { transform: translateY(0); }
    100% { transform: translateY(calc(50vh + 100%)); }
  }

  @media (min-height: 580px) {
    top: 0;
    bottom: 0;
  }
`

const StyledActionButtonSvg = styled(ActionButtonSvg)`
  position: absolute;
  top: -1.7rem;
  right: 0;
  z-index: 2000;
`

let closeTimeout

export default function FlipCardModal({ clown }) {
  const intl = useIntl()
  const { closeModal } = useModal()
  const [close, setClose] = useState(false)

  useEffect(() => {
    if (close) {
      closeTimeout = setTimeout(closeModal, 400)
      return () => clearTimeout(closeTimeout)
    }
  }, [close])

  const btnColors = [
    ["gray", 0],
    ["#3a3a3a", 0.5],
    ["#000", 1],
  ]

  return (
    <ModalWrapper standardUi={false}>
      <Wrapper>
        <Bg onClick={() => setClose(true)} className={close ? "close" : ""} />
        <Inner className={close ? "close" : ""}>
          <StyledActionButtonSvg onClick={() => setClose(true)} gradient={btnColors}>
            <CloseIcon />
            {intl.formatMessage({ id: "close" })}
          </StyledActionButtonSvg>
          <FlipCard clown={clown} />
        </Inner>
      </Wrapper>
    </ModalWrapper>
  )
}
