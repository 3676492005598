import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import CookiesDisabledMessage from "./CookiesDisabledMessage"

import { useStore } from "../hooks/store"
import timeToSeconds from "../utils/time-to-seconds"
import getYouTubeIdFromUrl from "../utils/get-youtube-id-from-url"
import getQueryStringFromObject from "../utils/get-query-string-from-object"

const Wrapper = styled.div`
  display: flex;
`

const Inner = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  align-self: center;
  flex-direction: column;
  height: auto;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  min-width: 200px;
  background: black;
  overflow: hidden;
`

const VideoWrapper = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin: auto;
  overflow: hidden;
  background: black;
`

const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
`

export default function VideoPlayer({ lang, id, title, provider, audioDescription, start, end, playlist }) {
  const intl = useIntl()
  const [store] = useStore()
  const [videoId, setVideoId] = useState(store.audioDescriptionEnabled && audioDescription ? audioDescription : id || get(playlist[0], "id"))
  const videoUrl = provider === "youtube" ?
    `https://www.youtube-nocookie.com/embed/${(getYouTubeIdFromUrl(videoId) || "")}`.trim() :
    videoId

  useEffect(() => {
    setVideoId(store.audioDescriptionEnabled && audioDescription ? audioDescription : id || get(playlist[0], "id"))
  }, [store.audioDescriptionEnabled])

  const getYouTubeOptions = () => {
    const obj = {
      autoplay: 1,
      controls: 1,
      playsinline: 1,
      cc_lang_pref: "auto",
      noCookie: true,
      rel: 0,
      modestbranding: 1,
      hl: lang,
    }

    if (lang !== "sv") {
      obj.cc_load_policy = 1
    }

    if (start) {
      obj.start = timeToSeconds(start)
    }

    if (end) {
      obj.end = timeToSeconds(end)
    }

    return obj
  }

  const options = provider === "youtube" ? getYouTubeOptions() : {}
  const filteredVideos = playlist ? playlist.filter((v) => v.provider === "youtube") : []

  if (filteredVideos.length > 1) {
    options.playlist = playlist.map((n) => getYouTubeIdFromUrl(n.id)).join(",")
  }

  const disabled = (provider === "youtube" && !store.cookies.youtube) || (provider === "svt_play" && !store.cookies.svt_play)

  return (
    <Wrapper>
    {disabled ?
      <CookiesDisabledMessage lang={lang} provider={provider} />
    :
      <Inner>
        <VideoWrapper>
          <Iframe
            allowFullScreen="1"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            title={`${intl.formatMessage({ id: "playerFor" })} ${title}`}
            width="640"
            height="360"
            src={`${videoUrl}${getQueryStringFromObject(options)}`} />
        </VideoWrapper>
      </Inner>
    }
    </Wrapper>
  )
}
