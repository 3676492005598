import React, { useState, useEffect, useRef } from "react"
import styled, { keyframes } from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useModal } from "../../hooks/modal"

import closeIcon from "../../images/close-plaster.svg"
import whiteArrowSvg from "../../images/arrow-white-2.svg"

import ModalWrapper from "./ModalWrapper"

const lightboxAnimateIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`

const lightboxContentAnimateIn = keyframes`
  0%    { opacity: 0; }
  50%   { opacity: 0; }
  100%  { opacity: 100%; }
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.9);
  animation: ${lightboxAnimateIn} 0.3s forwards;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
  }

  :focus {
    outline: 0;
    box-shadow: none;
  }
`

const CloseButton = styled.button`
  position: fixed;
  top: 0.0625rem;
  right: 0.125rem;
  z-index: 10;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  padding: 0;
  background: none;
  animation: ${lightboxContentAnimateIn} 0.6s forwards;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  position: absolute;
  top: 2.625rem;
  left: 0;
  display: flex;
  align-items: safe center;
  justify-content: center;
  width: 100%;
  min-height: calc(100% - 3.625rem);
  padding-bottom: 4.625rem;
  animation: ${lightboxContentAnimateIn} 0.6s forwards;

  img {
    width: 100%;
    max-width: ${props => props.maxWidth};
    height: auto;
  }
`

const NavButton = styled.button`
  position: fixed;
  bottom: 1.125rem;
  width: 2.375rem;
  height: 2.375rem;
  padding: 0;
  border: 0.125rem solid white;
  border-radius: 50%;
  background: black;
  animation: ${lightboxContentAnimateIn} 0.6s forwards;

  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background: url(${whiteArrowSvg}) no-repeat 0.45rem center;
    background-size: 1.375rem 2.125rem;
  }

  ${props => props.prev && `
    left: 1.375rem;
    transform: rotate(180deg);
    filter: drop-shadow(0 -0.125rem 0.25rem rgba(0, 0, 0, 0.25));
  `}

  ${props => props.next && `
    right: 1.375rem;
    filter: drop-shadow(0 0.125rem 0.25rem rgba(0, 0, 0, 0.25));
  `}
`

export default function LightboxModal({ images, index = 0 }) {
  const intl = useIntl()
  const ref = useRef()
  const { closeModal } = useModal()
  const [currentIndex, setCurrentIndex] = useState(index)
  const [isHidden, setIsHidden] = useState(false)

  useEffect(() => {
    if (currentIndex !== index) {
      ref.current.scrollTop = 0
      ref.current.focus()

      setIsHidden(true)

      setTimeout(() => {
        setIsHidden(false)
      }, 50)
    }
  }, [currentIndex])

  return (
    <ModalWrapper standardUi={false}>
      <CloseButton onClick={closeModal} aria-label={intl.formatMessage({ id: "close" })}>
        <img src={closeIcon} alt="" role="presentation" />
      </CloseButton>
      <Wrapper ref={ref} tabIndex="-1">
        <Content maxWidth={images[currentIndex].maxWidth}>
          {!isHidden && images[currentIndex].FullSize}
        </Content>
      </Wrapper>
      {currentIndex > 0 &&
        <NavButton onClick={() => setCurrentIndex(currentIndex - 1)} prev>
          <span className="structural">{intl.formatMessage({ id: "previous" })}</span>
        </NavButton>
      }
      {currentIndex < images.length - 1 &&
        <NavButton onClick={() => setCurrentIndex(currentIndex + 1)} next>
          <span className="structural">{intl.formatMessage({ id: "nextNasta" })}</span>
        </NavButton>
      }
    </ModalWrapper>
  )
}
