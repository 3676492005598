import React from "react"
import styled from "styled-components"

import ModalWrapper from "./ModalWrapper"
import HildingVilding from "../HildingVilding"

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
`

export default function HildingVildingModal({ onClose, ...props }) {
  return (
    <ModalWrapper standardUi={false} onClose={onClose}>
      <Bg onClick={onClose} />
      <HildingVilding onClose={onClose} {...props} />
    </ModalWrapper>
  )
}
