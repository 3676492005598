import React, { useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import HvModalWrapper from "./HvModalWrapper"
import VildingScore from "../VildingScore"
import MediaThumbnail from "../MediaThumbnail"
import ConfirmDialog from "../ConfirmDialog"

import { useStore } from "../../hooks/store"
import { getGames, gameStructure, getGame, getGameData } from "../../games"

import trashCanIcon from "../../images/trash-can-icon.svg"
import hvLogo from "../../images/hildingvilding_spion.svg"

const SpeechBubble = styled.p`
  position: relative;
  width: calc(100% - 4.8125rem);
  margin: 1.125rem 0 2.25rem;
  padding: 0.875rem;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 0.3125rem;
  font-size: 0.875rem;
  line-height: 1.125rem;

  ::after {
    content: "";
    position: absolute;
    top: 0.75rem;
    right: -1.75rem;
    width: 0;
    height: 0;
    border-top: 1.1875rem solid rgba(0, 0, 0, 0.2);
    border-right: 1.75rem solid transparent;
  }

  @media (min-width: 560px) {
    font-size: 1rem;
    line-height: 1.5;
  }
`

const HildingVilding = styled.img`
  position: absolute;
  top: 4rem;
  right: 1rem;
  width: 3.0625rem;
  height: auto;
`

const Sum = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.75rem 1rem;
  border: 1px solid white;
  border-radius: 0.3125rem;
  background: #15599E;
  font: 1rem/1.4375rem ${props => props.theme.headingFont};
  color: white;
`

const StyledVildingScore = styled(VildingScore)`
  margin: 0 1.125rem 0 auto;
`

const ClearButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.isLarge ? 1.9375 : 1.6875}rem;
  height: ${props => props.isLarge ? 1.9375 : 1.6875}rem;
  padding: 0;
  border: 1px solid white;
  border-radius: 50%;
  background: none;
  transition: opacity 0.3s ease;

  &[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${props => !props.isLarge && `
    img {
      width: 0.9375rem;
      height: 0.9375rem;
    }
  `}
`

const GameList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    display: flex;
    align-items: center;
    padding: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    font-size: 0.875rem;

    :last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    > div:last-child {
      display: flex;
      align-items: center;
      margin-left: auto;
      padding: 0 1.1875rem 0 1rem;

      span {
        margin-right: 1.3125rem;
        font: 1rem/1 ${props => props.theme.headingFont};
      }
    }
  }

  @media (min-width: 560px) {
    li {
      font-size: 1rem;

      > div:last-child span {
        font-size: 1.125rem;
      }
    }
  }
`

const ThumbWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  width: 4.125rem;
  height: 4.125rem;
  margin-right: 1.125rem;
`

export default function VildingScoreModal({ lang, memories, quizes }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const [showConfirmDialog, setShowConfirmDialog] = useState(false)
  const totalScore = getGames().reduce((acc, curr) => (acc + (store[curr.lsKey] || 0)), 0)

  const clearScore = (lsKey) => {
    updateStore((store) => {
      if (lsKey) {
        store[lsKey] = 0
      } else {
        getGames().forEach((game) => {
          store[game.lsKey] = 0
        })
      }
    })
  }

  const handleConfirmReset = () => {
    setShowConfirmDialog(false)
    clearScore()
  }

  const getGameList = (row) =>
    row.map((id) => {
      const { hide, title, lsKey, ...gameProps } = getGameData({ id, lang, formatMessage: intl.formatMessage, quizes, memories, includeLink: false })
      const score = store[lsKey] || 0

      if (hide) {
        return null
      }

      return (
        <li key={id}>
          <ThumbWrapper>
            <MediaThumbnail {...gameProps} imageTransform />
          </ThumbWrapper>
          {title}
          <div>
            <span>{score}</span>
            <ClearButton disabled={!score} onClick={() => clearScore(lsKey)}>
              <img src={trashCanIcon} role="none" />
              <span className="structural">{intl.formatMessage({ id: "resetPoints" })}</span>
            </ClearButton>
          </div>
        </li>
      )
    })

  return (
    <HvModalWrapper
      borderWidth="0.625rem"
      background="linear-gradient(180deg, #084B97 0%, #185CA0 100%)"
    >
      <h2>{intl.formatMessage({ id: "yourVildingScore" })}</h2>
      <SpeechBubble>
        {intl.formatMessage({ id: "vildingScoreModalIntro" })}
      </SpeechBubble>
      <HildingVilding src={hvLogo} alt="Hilding Vilding" />
      <Sum>
        {intl.formatMessage({ id: "total" })}<span className="structural">:</span>
        <StyledVildingScore score={totalScore} />
        <ClearButton
          isLarge
          disabled={!totalScore}
          onClick={() => setShowConfirmDialog(true)}
          aria-expanded={showConfirmDialog}
        >
          <img src={trashCanIcon} role="none" />
          <span className="structural">{intl.formatMessage({ id: "resetPoints" })}</span>
        </ClearButton>
      </Sum>
      {showConfirmDialog &&
        <ConfirmDialog
          heading={intl.formatMessage({ id: "vildingScoreConfirmHeading" })}
          message={`${intl.formatMessage({ id: "vildingScoreConfirmDisclaimer" })}.`}
          confirmButtonLabel={intl.formatMessage({ id: "yesReset" })}
          onCancel={() => setShowConfirmDialog(false)}
          onConfirm={handleConfirmReset}
        />
      }
      <GameList>
        {gameStructure.map((row) => (
          getGameList(row)
        ))}
      </GameList>
    </HvModalWrapper>
  )
}
