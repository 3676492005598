import React from "react"
import styled from "styled-components"

import nl2br from "../utils/nl2br"

import cloudborder from "./../images/cloud-border-image.svg"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  border-image: url(${cloudborder}) round;
  border-image-slice: 25% 20% 25% 24% fill;
  border-image-width: 2.5rem;
  border-image-outset: 2rem;
  padding: .8em;
  padding-bottom: 1.2em;
  margin: auto auto 0;
  max-height: 80%;
  max-width: 100%;

  @media (max-height: 600px) {
    max-height: 65%;
  }

  @media (max-width: 374px) {
    border-image-width: 2rem;
    border-image-outset: 1rem;
  }
`

const Inner = styled.div`
  overflow: auto;
  margin: 0;
  padding: 1em 0;

  h1, h2, h3 {margin-top: 0;}
`

export default function CiceronSpeechBubble({
  dialogue = "",
  headline = null,
  audioPlayer = null,
  closeButton = null,
  ...props
}) {
  return (
    <Wrapper {...props}>
      <>
        {closeButton}
        {audioPlayer}
        {headline ?
          <Inner>{headline}<div dangerouslySetInnerHTML={{ __html: nl2br(dialogue) }} /></Inner>
          :
          <Inner dangerouslySetInnerHTML={{ __html: nl2br(dialogue) }} />
        }
      </>
    </Wrapper>
  )
}
