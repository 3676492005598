import React, { useState, createRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { usePageMetadata } from "../../pageMetadata"
import { useStore } from "../../hooks/store"
import useReducedMotion from "../../hooks/reducedMotion"
import trackEvent from "../../utils/track-event"
import { getLanguages, getLanguageName, getLanguageFlag } from "../../languages"

import ModalWrapper from "./ModalWrapper"
import ToggleControl from "../ToggleControl"
import Flag from "../Flag"
import InfoIcon from "../icons/Info"
import ReduceMotionInfo from "../ReduceMotionInfo"

import audioDescription from "../../images/svt_audiodesc_black.svg"
import movementIcon from "../../images/movement-icon.svg"

const Inner = styled.div`
  padding: 2rem;
`

const Fieldset = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.5rem -1rem;
  > * {
    margin: 0.5rem 1rem;
  }
`

const Field = styled.div`
  position: relative;
  display: grid;
  justify-items: start;
  font-family: ${props => props.theme.headingFont};
  text-transform: uppercase;
  grid-gap: 0.25rem;

  &:focus {
    outline: 0;
  }

  &,
  h3 {
    font-size: 0.875rem;
    line-height: 1.5;
  }

  h3 {
    margin: 0;
    font-weight: normal;
  }
`

const StyledLabel = styled.label`
  ${props => props.icon && `
    &::before {
      content: url(${props.icon});
      display: inline-block;
      width: ${props.iconWidth || "1.2em"};
      height: ${props.iconHeight || "1.2em"};
      margin-top: -0.2rem;
      margin-right: 0.4rem;
      vertical-align: middle;
    }
  `}
`

const LanguageList = styled.ul`
  list-style: none;
  padding: 0;
  margin: -0.5rem;
  display: flex;
  flex-wrap: wrap;
  > * {
    margin: 0.5rem;
  }
  padding: 0.5rem 0 2rem;
`

const LanguageListItem = styled.li``

// Use a regular <a> tag instead of Link in order to
// force a page reload and thus update the favicon.
const LanguageLink = styled.a`
  color: inherit;
  text-decoration: inherit;
  border-radius: 2em;
  padding: 0.4em;

  &.active {
    border: 1px solid #d2d2d2;
  }

  &:hover {
    background: rgba(209, 209, 209, 0.5);
  }

  > * {
    display: inline-block;
    margin-right: 0.5rem;
    vertical-align: middle;
  }
`

const LanguageText = styled.span`
  font-family: ${props => props.theme.headingFont};
  font-style: normal;
  font-weight: normal;
`

const StyledFlag = styled(Flag)`
  width: 25px;
  height: 25px;
  pointer-events: none;
`

const InfoButton = styled.button`
  position: absolute;
  bottom: 1.375rem;
  right: 0.25rem;
  width: 1.3125rem;
  height: 1.3125rem;
  padding: 0.5rem;
  border: 0;
  border-radius: 50%;
  background: white;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const ReduceMotionInfoWrapper = styled.div`
  &:focus {
    outline: 0;
  }
`

export default function SettingsModal({ lang }) {
  const intl = useIntl()
  const [showReduceMotionInfo, setShowReduceMotionInfo] = useState(false)
  const { translations } = usePageMetadata()
  const [store, updateStore] = useStore()
  const shouldReduceMotion = useReducedMotion()
  const motionFieldRef = createRef()
  const reduceMotionRef = createRef()

  const getReduceMotionRef = () =>
    reduceMotionRef.current || document.getElementById("reduce-motion-info")

  const getMotionFieldRef = () =>
    motionFieldRef.current || document.getElementById("motion-field")

  const handleInfoClick = () => {
    setShowReduceMotionInfo(true)
    const reduceMotionRef = getReduceMotionRef()

    if (reduceMotionRef) {
      reduceMotionRef.focus()
    }
  }

  const handleCloseInfo = () => {
    setShowReduceMotionInfo(false)
    const motionFieldRef = getMotionFieldRef()

    if (motionFieldRef) {
      motionFieldRef.focus()
    }
  }

  const track = (name, value) => {
    trackEvent({ category: "Settings", action: value ? "Activate" : "Deactivate", name })
  }

  return (
    <ModalWrapper
      header={intl.formatMessage({ id: "settingsModalHeader" })}
      maxWidth="40rem"
    >
      <Inner>
        <Field>
          <h3>{intl.formatMessage({ id: "languageSettingLabel" })}</h3>
          <LanguageList>
            {Object.keys(getLanguages(true)).map(language => (
              <LanguageListItem key={language}>
                <LanguageLink
                  lang={language}
                  href={translations && translations[language] ?
                    `/${language}/${translations[language]}` :
                    `/${language}`
                  }
                  className={language === lang ? "active" : null }
                  {...(language === lang ? { ["aria-current"]: "page" } : {})}
                >
                  <StyledFlag code={getLanguageFlag(language)} />
                  <LanguageText>{getLanguageName(language)}</LanguageText>
                </LanguageLink>
              </LanguageListItem>
            ))}
          </LanguageList>
        </Field>
        <Fieldset>
          <Field ref={motionFieldRef} id="motion-field" tabIndex="-1">
            <StyledLabel
              htmlFor="settings-modal-animations-enabled"
              icon={movementIcon}
              iconWidth="1.25rem"
              iconHeight="0.875rem"
            >
              {intl.formatMessage({ id: "animationsEnabledSettingLabel" })}
            </StyledLabel>
            <ToggleControl
              buttonID="settings-modal-animations-enabled"
              value={shouldReduceMotion ? false : store.animationsEnabled}
              disabled={shouldReduceMotion}
              onChange={value => {
                updateStore(store => {
                  store.animationsEnabled = value
                })
                track("Reduce Motion", value)
              }}
            />
            {shouldReduceMotion &&
              <InfoButton
                onClick={handleInfoClick}
                aria-pressed={showReduceMotionInfo}
                aria-controls="reduce-motion-info"
              >
                <span className="structural">{intl.formatMessage({ id: "moreInfo" })}</span>
                <InfoIcon fill="#e00101" />
              </InfoButton>
            }
          </Field>
          <Field>
            <StyledLabel
              htmlFor="settings-modal-audiodescription-enabled"
              icon={audioDescription}
            >
              {intl.formatMessage({ id: "audioDescriptionEnabledSettingLabel" })}
            </StyledLabel>
            <ToggleControl
              buttonID="settings-modal-audiodescription-enabled"
              value={store.audioDescriptionEnabled}
              onChange={value => {
                updateStore(store => {
                  store.audioDescriptionEnabled = value
                })
                track("Audio Description", value)
              }}
            />
          </Field>
        </Fieldset>
      </Inner>
      <ReduceMotionInfoWrapper tabIndex="-1" id="reduce-motion-info" ref={reduceMotionRef}>
        {showReduceMotionInfo &&
          <ReduceMotionInfo lang={lang} onClose={handleCloseInfo} />
        }
      </ReduceMotionInfoWrapper>
    </ModalWrapper>
  )
}
