import React from "react"

import ModalWrapper from "./ModalWrapper"
import Tryggwebb from "../Tryggwebb"

export default function TryggwebbModal({ onClose, ...props }) {
  return (
    <ModalWrapper standardUi={false} onClose={onClose}>
      <Tryggwebb
        onClose={onClose}
        {...props}
      />
    </ModalWrapper>
  )
}
