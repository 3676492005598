import React from "react"
import styled from "styled-components"

import { useStore } from "../hooks/store"
import { useIntl } from "gatsby-plugin-intl"
import { getGames } from "../games"

import iconEdit from "../images/hv-icon-edit.svg"

const Wrapper = styled.span`
  display: block;
  position: relative;
  text-align: center;
  font: 1.875rem/2.3rem ${props => props.theme.headingFont};
  color: #eee;

  ${props => props.showEdit && `
    &::after {
      content: "${props.editLabel}";
      font-size: 0;
      color: rgba(0,0,0,0);
      display: inline-block;
      position: relative;
      vertical-align: top;
      width: 1.4rem;
      height: 1.4rem;
      margin-left: 0.3rem;
      background: rgba(255,255,255,0.7) url(${iconEdit}) no-repeat center center;
      border-radius: 0.1875rem;
    }
  `}

  span {
    position: relative;
    display: inline-block;
    height: 2.125rem;
    margin: 0 0.0625rem;
    padding: 0 0.1875rem;
    border-radius: 0.1875rem;
    background: #333;
    text-align: center;

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 100%;
      height: 0.0625rem;
      background: rgba(0, 0, 0, 0.3);
      box-shadow: 0px 0.598765px 0px rgba(255, 255, 255, 0.5);
    }
  }
`

export default function VildingScore({ score, ...props }) {
  const [store] = useStore()
  const intl = useIntl()

  const vildingScore = (score || getGames().reduce((acc, curr) => (acc + (store[curr.lsKey] || 0)), 0))
    .toString()
    .split("")
    .map((number, index) => ({ id: index, number }))

  return (
    <Wrapper {...props} editLabel={intl.formatMessage({ id: "resetPoints" })}>
      {vildingScore.map((item) => (
        <span key={item.id}>{item.number}</span>
      ))}
    </Wrapper>
  )
}
