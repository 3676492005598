import React from "react"
import styled from "styled-components"

import ChitChatImportSvg from "./ChitChatImportSvg"

const StyledSvg = styled(ChitChatImportSvg)`
  stroke-width: 0.1rem;
  ${props => props.stroke && `
    g * {
      stroke: ${props.stroke};
    }
  `}
`

export default function ChitChatAvatarIcon({ avatar, color }) {
  return (
    <StyledSvg avatar={avatar} stroke={color} width="30" height="30" />
  )
}
