import React, { useState, useEffect, createRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ModalWrapper from "./ModalWrapper"
import CloseIcon from "../icons/Close"
import ChitChatBubble from "../ChitChatBubble"
import ChitChatForm from "../ChitChatForm"
import ErrorMessage from "../ErrorMessage"

import { useModal } from "../../hooks/modal"
import getChitChatMessages from "../../utils/get-chitchat-messages"

import closeIcon from "../../images/close-plaster-dark-gray.svg"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.95);

  @supports ((-webkit-backdrop-filter: blur(1px)) or (backdrop-filter: blur(1px))) {
    background: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(10px);
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.0625rem;
  right: 0.125rem;
  z-index: 10;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  padding: 0;
  background: none;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Inner = styled.div`
  max-width: 37.5rem;
  margin: 0 auto;
`

const MessagesWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 6.25rem);
  padding: 3.4375rem 1.625rem 1rem;
  overflow: auto;
`

const Messages = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;

  li:focus {
    outline: 0;
    box-shadow: none;
  }
`

const LoadMoreButtonWrapper = styled.div`
  margin-top: 1.25rem;
  text-align: center;
`

const LoadMoreButton = styled.button`
  padding: 0.5rem 0.625rem;
  border: 1px solid ${props => props.theme.roomColors[8].darker};
  border-radius: 9999px;
  color: ${props => props.theme.roomColors[8].darker};
  background: none;
`

const FormWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 2rem 1rem;
  border-top: 1px solid #c4c4c4;
`

export default function ChitChatModal({ lang, onUpdate }) {
  const ref = createRef()
  const intl = useIntl()
  const { closeModal } = useModal()
  const [messages, setMessages] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [pagination, setPagination] = useState("")
  const [hasError, setHasError] = useState(false)

  const getMessages = () => {
    setIsLoading(true)
    setHasError(false)

    getChitChatMessages({ lang, after: pagination })
      .then(({ after, data }) => {
        setMessages(messages.concat(data))
        setIsLoading(false)

        if (pagination) {
          setTimeout(() => {
            document.getElementById(`msg_${data[0].id}`).focus()
          })
        }

        setPagination(after ? `${after[0]},${after[1]["@ref"].id}` : "")
      })
      .catch((error) => {
        console.log(error)
        setHasError(true)
        setIsLoading(false)
      })
  }

  const handleSendSuccess = (id, message, timestamp, username, color, avatar) => {
    ref.current.scrollTop = 0

    const msg = {
      id: id || (messages.length + 1),
      message,
      timestamp,
      username,
      color,
      avatar,
    }

    setMessages([msg].concat(messages))
    onUpdate(msg)
  }

  useEffect(() => {
    getMessages()
  }, [])

  return (
    <ModalWrapper standardUi={false}>
      <Wrapper>
        <CloseButton onClick={closeModal} aria-label={intl.formatMessage({ id: "close" })}>
          <img src={closeIcon} alt="" role="presentation" />
        </CloseButton>
        <h2 className="structural">ChitChat</h2>
        <MessagesWrapper ref={ref}>
          <Inner>
            {hasError &&
              <ErrorMessage onRetry={getMessages} />
            }
            {isLoading && messages.length === 0 &&
              <>
                <span className="structural">{intl.formatMessage({ id: "loading" })}</span>
                <ChitChatBubble placeholder />
                <ChitChatBubble placeholder />
                <ChitChatBubble placeholder />
              </>
            }
            {messages.length > 0 &&
              <Messages>
                {messages.map((message) => (
                  <li key={message.id} id={`msg_${message.id}`} tabIndex="-1">
                    <ChitChatBubble lang={lang} message={message} />
                  </li>
                ))}
              </Messages>
            }
            {!!pagination &&
              <LoadMoreButtonWrapper>
                <LoadMoreButton onClick={getMessages}>
                  {intl.formatMessage({ id: isLoading ? "loading" : "load" })} {intl.formatMessage({ id: "more" })}{isLoading ? "..." : ""}
                </LoadMoreButton>
              </LoadMoreButtonWrapper>
            }
          </Inner>
        </MessagesWrapper>
        <FormWrapper>
          <Inner>
            <ChitChatForm lang={lang} onSendSuccess={handleSendSuccess} />
          </Inner>
        </FormWrapper>
      </Wrapper>
    </ModalWrapper>
  )
}
