import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import HvModalWrapper from "./HvModalWrapper"
import PrintDownload from "../PrintDownload"

const Disclaimer = styled.div`
  p {
    max-width: 27.5rem;
    margin: 0 0 1.25rem;
    font-size: 1rem;
    line-height: 1.5;
  }
`

const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 2rem;
  }
`

export default function PrintModal({ lang, items, disclaimer }) {
  const intl = useIntl()

  return (
    <HvModalWrapper
      background="linear-gradient(180deg, #044C9C 0%, #64ACD1 100%)"
      logo={`/assets/hilding_vilding/HV_Cover_SkrivUt_${lang !== "sv" ? "Eng" : "Sve"}.png`}
      logoAltText={intl.formatMessage({ id: "print" })}
    >
      <h2>{intl.formatMessage({ id: "printModalHeading" })}</h2>
      <Disclaimer dangerouslySetInnerHTML={{ __html: get(disclaimer, "text.html") }} />
      <List>
        {items.map((item) => (
          <li key={item.title}>
            <PrintDownload {...item} />
          </li>
        ))}
      </List>
    </HvModalWrapper>
  )
}
