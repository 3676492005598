export default function getColorBrightness(color) {
  const brightness = (r,g,b) => ( (299 * r) + (587 * g) + (114 * b) ) / 1000

  if(!color)
    return

  // catch 3-digit hex
  if(color.length === 4) {
    color = color.split('')
    color.shift()
    color = color.map( hex => hex + hex)
    color.unshift("#")
    color = color.join('')
  }

  const hex = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
  if (hex) {
      let r = parseInt(hex[1], 16)
      let g = parseInt(hex[2], 16)
      let b = parseInt(hex[3], 16)

      return brightness(r,g,b)
  }

  // should work for rgb or rgba - captures only rgb components
  const rgb = /(\d{1,3})\s?,\s?(\d{1,3})\s?,\s?(\d{1,3})/i.exec(color)
  if (rgb) {
    let r = parseInt(rgb[1], 16)
    let g = parseInt(rgb[2], 16)
    let b = parseInt(rgb[3], 16)

    return brightness(r,g,b)
  }

}
