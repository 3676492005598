import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

const Dialog = styled.div`
  position: relative;
  width: 19rem;
  padding: 2.5rem 1.25rem 2rem;
  border-radius: 0.625rem;
  background: white;
  box-shadow: 0 0.25rem 1.875rem rgba(0, 0, 0, 0.8);

  &:focus {
    outline: 0;
  }

  h3 {
    margin: 0 0 0.625rem;
    font: 1.1875rem/110% ${props => props.theme.headingFont};
    text-align: center;
    color: black;
  }

  p {
    margin: 0;
    font-size: 0.9375rem;
    line-height: 1.1875rem;
    text-align: center;
    color: #333;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: ${props => props.hasCancelButton ? "space-between" : "center"};
  margin-top: 2.5rem;
`

const CancelButton = styled.button`
  margin-left: 1.4375rem;
  padding: 0.4375rem 1.25rem;
  border: 0;
  font-size: 1rem;
  color: #4CB2F8;
  background: none;
`

const Button = styled.button`
  margin-right: 0.625rem;
  padding: 0.4375rem 0.875rem;
  border: 1px solid #4CB2F8;
  border-radius: 9999px;
  background: none;
  font-size: 1rem;
`

export default function ConfirmDialog({
  heading,
  message,
  confirmButtonLabel,
  cancelButtonLabel,
  onConfirm,
  onCancel,
  ...props
}) {
  const intl = useIntl()
  const dialogRef = createRef()

  useEffect(() => {
    if (dialogRef.current) {
      dialogRef.current.focus()
    }
  }, [])

  return (
    <Wrapper>
      <Overlay onClick={onCancel || onConfirm} />
      <Dialog tabIndex="-1" ref={dialogRef} {...props}>
        <h3>{heading}</h3>
        <p>{message}</p>
        <ButtonWrapper hasCancelButton={!!onCancel}>
          {onCancel &&
            <CancelButton onClick={onCancel}>
              {cancelButtonLabel || intl.formatMessage({ id: "no" })}
            </CancelButton>
          }
          <Button onClick={onConfirm}>
            {confirmButtonLabel}
          </Button>
        </ButtonWrapper>
      </Dialog>
    </Wrapper>
  )
}
