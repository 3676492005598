import React from "react"
import * as Flags from "../flags"
import flagMask from "../flagMask.svg"
import styled from "styled-components"

const Wrapper = styled.span`
  position: relative;
  display: block;
  ${props => props.multi ? `
    width: 1.375rem;
    height: 1.375rem;
  ` : `
    width: 1.625rem;
    height: 1.625rem;
  `}
`

const Image = styled.span`
  position: relative;
  z-index: 2;
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  border: 2px solid #e8e8e8;

  ${props => props.multi && `
    top: -0.2rem;
    left: -0.25rem;
  `}

  ${props => props.multi && props.index > 0 && `
    position: absolute;
    top: 0.2rem;
    left: 0.4375rem;
    z-index: 1;
  `}
`

export default function Flag({ code, border, ...props }) {
  const codes = code.split(",")
  const flagImages = codes.map((flagCode) => `url(${Flags[flagCode]})`)

  return (
    <Wrapper {...props} multi={codes.length > 1}>
      {codes.map((flagCode, index) => (
        <Image
          key={flagCode}
          code={flagCode}
          multi={codes.length > 1}
          border={border}
          index={index}
          style={{ backgroundImage: flagImages[index] || "" }}
        />
      ))}
    </Wrapper>
  )
}
