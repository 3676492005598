import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import flatten from "lodash/flatten"
import get from "lodash/get"

import { useModal } from "../hooks/modal"

import RoomContentScroller from "./RoomContentScroller"
import MediaThumbnail from "./MediaThumbnail"
import PlayAllButton from "./PlayAllButton"

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;

  @media (min-width: 895px) {
    justify-content: flex-start;
  }
`

const StyledMediaThumbnail = styled(MediaThumbnail)`
  > * {
    ${props => props.noBorderRadiusLeft && `
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}
    ${props => props.noBorderRadiusRight && `
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
    ${props => !props.noBorderRadiusLeft && !props.noBorderRadiusRight && `
      border-radius: 0;
    `}
  }
`

const VideoSection = ({ lang, title, videos, showPlayAll = true, specialType }) => {
  const intl = useIntl()
  const { openModal } = useModal()
  const isTimeline = (get(videos[0], "chapters") || []).length > 0
  const items = flatten(videos.map((video) => {
    if (video.chapters && video.chapters.length) {
      return (video.chapters[0].time.trim() === "00:00" ? [] : [
        ({ lazyBp }) => (
          <StyledMediaThumbnail
            provider={video.provider}
            title={video.title}
            lang={lang}
            background={video.image}
            onClick={(e) => openModal(e, "video", {
              lang,
              id: video.id,
              title: video.title,
              provider: video.provider,
              audioDescription: video.audio_description,
            })}
            noBorderRadiusRight
            lazyBp={lazyBp}
            imageTransform
          />
        )
      ]).concat(video.chapters.map((chapter, index) => {
        const startsOnZero = chapter.time.trim() === "00:00"
        const chapterProps = {
          provider: video.provider,
          title: chapter.title,
          background: chapter.image,
          lang,
          imageTransform: true,
        }

        if (video.provider === "external-link") {
          chapterProps.href = video.id
        } else {
          const modalProps = {
            lang,
            id: video.id,
            title: video.title,
            provider: video.provider,
            start: chapter.time,
            audioDescription: video.audio_description,
          }

          const nextChapter = index < video.chapters.length - 1 ? video.chapters[index + 1] : null

          if (nextChapter) {
            modalProps.end = nextChapter.time
          }

          chapterProps.onClick = (e) => openModal(e, "video", modalProps)
        }

        return (
          ({ lazyBp }) => (
            <StyledMediaThumbnail
              {...chapterProps}
              noBorderRadiusLeft={index === video.chapters.length - 1}
              noBorderRadiusRight={startsOnZero}
              lazyBp={lazyBp}
            />
          )
        )
      }))
    }

    const videoProps = {
      provider: video.provider,
      title: video.title,
      background: video.image,
      lang,
      imageTransform: true,
    }

    if (video.provider === "external-link") {
      videoProps.href = video.id
    } else {
      videoProps.onClick = (e) => openModal(e, "video", {
        lang,
        id: video.id,
        title: video.title,
        provider: video.provider,
        audioDescription: video.audio_description,
      })
    }

    return ({ lazyBp }) => (
      <MediaThumbnail
        {...videoProps}
        specialType={specialType}
        titleIsVisible={specialType === "vlog"}
        lazyBp={lazyBp}
      />
    )
  }))

  return (
    <div>
      <Header>
        {title && <h2>{title}</h2>}
        {showPlayAll && videos.length > 3 &&
          <PlayAllButton onClick={(e) => openModal(e, "video", { lang, videos, title })}/>
        }
      </Header>
      <RoomContentScroller
        items={items}
        isTimeline={isTimeline}
      />
    </div>
  )
}

export default VideoSection
