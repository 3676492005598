export default function flickityA11y(onReady = true, hasPrevNext = true, hasDots = false) {
  const slides = document.querySelectorAll(".flickity-slider > *")

  for (let i = 0, l = slides.length; i < l; i++) {
    slides[i].removeAttribute("aria-hidden")
  }

  if (onReady) {
    if (hasPrevNext) {
      const buttons = document.querySelectorAll(".flickity-prev-next-button")

      for (let i = 0, l = buttons.length; i < l; i++) {
        buttons[i].setAttribute("aria-hidden", "true")
      }
    }

    if (hasDots) {
      const dotWrappers = document.querySelectorAll(".flickity-page-dots")

      for (let i = 0, l = dotWrappers.length; i < l; i++) {
        dotWrappers[i].setAttribute("aria-hidden", "true")
      }
    }
  }
}
