import React from "react"

export default function CloseIcon({fill = "#fff"}) {

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="22"
      viewBox="0 0 113.39 113.39"
      className="close-icon"
    >
      <path
        fill={fill}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M56.69 26.15L76.54 6.3c8.4-8.4 22.14-8.4 30.54 0 8.4 8.4 8.4 22.14 0 30.54L87.23 56.69 56.69 26.15zM56.69 87.23l-19.85 19.85c-8.4 8.4-22.14 8.4-30.54 0-8.4-8.4-8.4-22.14 0-30.54l19.85-19.85 30.54 30.54zM87.23 56.69l19.85 19.85c8.4 8.4 8.4 22.14 0 30.54-8.4 8.4-22.14 8.4-30.54 0L56.69 87.23l30.54-30.54zM26.15 56.69L6.3 36.84c-8.4-8.4-8.4-22.14 0-30.54 8.4-8.4 22.14-8.4 30.54 0l19.85 19.85-30.54 30.54zM39.9 52.72c-2.19 2.19-2.19 5.75 0 7.94s5.75 2.19 7.94 0c2.19-2.19 2.19-5.75 0-7.94-2.2-2.19-5.75-2.19-7.94 0zM52.72 39.9c-2.19 2.19-2.19 5.75 0 7.94 2.19 2.19 5.75 2.19 7.94 0 2.19-2.19 2.19-5.75 0-7.94s-5.74-2.2-7.94 0zm0 25.65c-2.19 2.19-2.19 5.75 0 7.94 2.19 2.19 5.75 2.19 7.94 0 2.19-2.19 2.19-5.75 0-7.94-2.19-2.19-5.74-2.19-7.94 0zm12.83-12.83c-2.19 2.19-2.19 5.75 0 7.94 2.19 2.19 5.75 2.19 7.94 0 2.19-2.19 2.19-5.75 0-7.94-2.19-2.19-5.75-2.19-7.94 0z"
      />
    </svg>
  )
}
