import React, { useState, useEffect } from "react"
import styled from "styled-components"

import ModalWrapper from "./ModalWrapper"
import RoomMenu from "../RoomMenu"

import { useModal } from "../../hooks/modal"

const Bg = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.55);
  }
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(5px);
  animation: room-menu-fade-in 0.3s forwards;

  &.close {
    animation: room-menu-fade-out 0.3s forwards;
  }

  @keyframes room-menu-fade-in {
    0%   { opacity: 0; }
    100% { opacity: 1; }
  }

  @keyframes room-menu-fade-out {
    0%   { opacity: 1; }
    100% { opacity: 0; }
  }
`

const Inner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
`

let closeTimeout

export default function RoomMenuModal({ lang, room, ...props }) {
  const { closeModal } = useModal()
  const [close, setClose] = useState(false)

  useEffect(() => {
    if (close) {
      closeTimeout = setTimeout(closeModal, 400)
      return () => {
        closeModal()
        clearTimeout(closeTimeout)
      }
    }
  }, [close])

  return (
    <ModalWrapper standardUi={false}>
      <Wrapper className={close ? "close" : ""}>
        <Bg onClick={() => setClose(true)} />
        <Inner>
          <RoomMenu lang={lang} onClose={() => setClose(true)} room={room} />
        </Inner>
      </Wrapper>
    </ModalWrapper>
  )
}
