import React from "react"

export default function TurnIcon({ color = "currentColor", transform, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 58 36"
      width="58"
      height="36"
      stroke={color}
      fill="none"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      style={{ transform }}
      {...props}
    >
      <path d="M31 5C17.088 5 8.206 7.22 4.142 12.864-.744 19.649 1.991 30.068 20.089 30.068m0 0l-4.196-3.441m4.196 3.441L15.892 34M27 31c13.912 0 22.794-2.22 26.858-7.864 4.886-6.785 2.15-17.204-15.947-17.204m0 0l4.197 3.441m-4.196-3.441L42.108 2" />
    </svg>
  )
}
