import React, { useState, useEffect, createRef } from "react"
import styled from "styled-components"
import get from "lodash/get"
import { useIntl } from "gatsby-plugin-intl"

import getColorBrightness from "../utils/get-color-brightness"

import TurnIcon from "./icons/Turn"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  .flip-card-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    border: 0;
    border-radius: 0.3125rem;
    text-align: left;
    perspective: 500px;
  }

  .flip-card-front,
  .flip-card-back {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 2rem;
    border-radius: 0.3125rem;
    background-color: ${props => props.bgColor};
    color: ${ props => (getColorBrightness(props.bgColor) <= 125) ? "white" : "black" };
    transition: transform 0.6s;
    backface-visibility: hidden;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);

    &:focus {
      outline: 0;
    }
  }

  .flip-card-front {
    font-family: ${props => props.theme.headingFont};

    img {
      width: 100%;
      height: auto;
    }

    p {
      position: absolute;
      bottom: 2rem;
      left: 2rem;
      margin: 0;
      font-size: 1.125rem;
      line-height: 1.1;
    }
  }

  .flip-card-back {
    overflow: auto;
    transform: rotateY(-180deg);
  }

  &.flipped .flip-card-front {
    transform: rotateY(180deg);
  }

  &.flipped .flip-card-back {
    transform: rotateY(0deg);
  }

  h2,
  h3 {
    font-family: ${props => props.theme.headingFont};
  }

  h2 {
    margin: 0 0 1.5rem;
    text-align: center;
    font-size: 2rem;
    line-height: 1.1;
  }

  h3 {
    margin: 0 0 1rem;
    font-size: 1rem;
  }

  @media (min-width: 360px) and (min-height: 550px) {
    h2 {
      font-size: 2.5rem;
    }
  }
`

const Button = styled.button`
  position: absolute;
  bottom: 1.5rem;
  right: 2rem;
  width: 2.5rem;
  height: 1.8125rem;
  border: 0;
  padding: 0;
  background: none;
  color: ${ props => (getColorBrightness(props.bgColor) <= 125) ? "white" : "black" };

  svg {
    width: 100%;
    height: auto;
  }
`

const FlipCard = ({ clown }) => {
  const [flipped, setFlipped] = useState(false)
  const intl = useIntl()
  const frontRef = createRef()
  const backRef = createRef()

  useEffect(() => {
    if (flipped) {
      backRef.current.focus()
    } else {
      frontRef.current.focus()
    }
  }, [flipped])

  const getTurnIcon = color => {
    return getColorBrightness(props.bgColor) <= 125 ? <TurnIcon /> : <TurnIcon fill="black" />
  }

  return (
    <Wrapper className={flipped ? "flipped" : ""} bgColor={clown.bgColor}>
      <div className="flip-card-inner" onClick={() => setFlipped(!flipped)}>
        <div className="flip-card-front" ref={frontRef} tabIndex="-1" aria-hidden={flipped}>
          <img
            src={clown.image}
            alt={clown.imageAltText}
          />
          <p>{clown.clown_name}</p>
          <Button bgColor={clown.bgColor} onClick={() => setFlipped(true)} tabIndex={flipped ? "-1" : "0"}>
            <span className="structural">{intl.formatMessage({ id: "flipCard" })}</span>
            <TurnIcon />
          </Button>
        </div>
        <div className="flip-card-back" ref={backRef} tabIndex="-1" aria-hidden={!flipped}>
          <h2>{clown.clown_name}</h2>
          <div dangerouslySetInnerHTML={{ __html: get(clown, "text.html") }} />
          <Button bgColor={clown.bgColor} onClick={() => setFlipped(false)} tabIndex={flipped ? "0" : "-1"}>
            <span className="structural">{intl.formatMessage({ id: "flipCard" })}</span>
            <TurnIcon />
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

export default FlipCard
