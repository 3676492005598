if (typeof window !== "undefined") {
  require("whatwg-fetch")
}

export default function writeChitChatMessage(message, lang) {
  const url = `/.netlify/functions/write_chitchat_message`

  return window.fetch(url, {
    body: `message=${message}&lang=${lang}`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "post",
  })
  .then(res => res.json())
}
