import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import Logo from "./LogoFull"

const StyledLink = styled(Link)`
  display: inline-block;

  &.sv {
    width: 9.5rem;

    &.large {
      width: 13.25rem;
    }
  }

  &.en {
    width: 12.5rem;

    &.large {
      width: 17.43rem;
    }
  }
`

export default function LogoLink({ lang, room, large, ...props }) {
  const intl = useIntl()

  return (
    <StyledLink
      to={`/${lang}`}
      {...(room ? {} : { lang })}
      {...props}
      className={`${props.className || ""} ${lang} ${large ? "large" : ""}`}
    >
      <Logo lang={lang} room={room} />
      <span className="structural">
        {intl.formatMessage({ id: "goBackHome" })}
      </span>
    </StyledLink>
  )
}
