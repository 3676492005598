import React from "react"
import { useIntl } from "gatsby-plugin-intl"
import { graphql } from "gatsby"

import RoomMock from "../components/RoomMock"
import RoomContentSection from "../components/RoomContentSection"
import FaqSection from "../components/FaqSection"
import VoSection from "../components/VoSection"
import VideoSection from "../components/VideoSection"
import QuestionPanel from "../components/QuestionPanel"

export default function RoomTemplate4({
  data: { allVoYaml, allVideosYaml, questionPanelYaml, ciceronYaml },
  pageContext: { lang, pagePath, translations },
}) {
  const intl = useIntl()
  const painTalkVideos = allVideosYaml.nodes.find((x) => x._id === "smartsnack")

  return (
    <RoomMock
      lang={lang}
      path={pagePath}
      room={4}
      title={intl.formatMessage({ id: "roomHeading4" })}
      shareImage={{
        url: `/assets/share-images/NW_Delning6_Fragor_svar_${lang === "sv" ? "Sve" : "Eng"}.jpg`,
        width: 1200,
        height: 630,
      }}
      ciceronData={ciceronYaml}
      translations={translations}
    >
      <RoomContentSection heading={intl.formatMessage({ id: "faqHeading" })}>
        <FaqSection lang={lang} room={4} />
      </RoomContentSection>

      <RoomContentSection heading={intl.formatMessage({ id: "whatIsWhat" })}>
        <VoSection lang={lang} items={allVoYaml.nodes} />
      </RoomContentSection>

      <RoomContentSection>
        <VideoSection lang={lang} title={painTalkVideos.title} videos={painTalkVideos.videos} />
      </RoomContentSection>

      {questionPanelYaml &&
        <RoomContentSection heading={questionPanelYaml.title}>
          <QuestionPanel
            lang={lang}
            emailContactImage={questionPanelYaml.email_contact_image}
            questions={questionPanelYaml.questions}
          />
        </RoomContentSection>
      }
    </RoomMock>
  )
}

export const pageQuery = graphql`
  query($lang: String) {
    allVoYaml(filter: { lang: { eq: $lang } }, sort: { fields: order }) {
      nodes {
        title
        images {
          image
          isThumbnail
        }
      }
    }
    allVideosYaml(filter: { lang: { eq: $lang }, page: { eq: "Frågor och svar" } }) {
      nodes {
        _id
        title
        videos {
          id
          provider
          title
          image
          audio_description
        }
      }
    }
    questionPanelYaml(lang: { eq: $lang }, _id: { eq: "childrens-voices" }) {
      title
      email_contact_image
      questions {
        question
        answers {
          name
          video_id
          image
        }
      }
    }
    ciceronYaml(lang: { eq: $lang }, _id: { eq: "rum-4" }) {
      text
      sound
    }
  }
`
