import React from "react"
import styled from "styled-components"

const Wrapper = styled.span`
  display: inline-block;
  max-width: 100% !important;
  max-height: 100% !important;
  overflow: visible;
  transition-timing-function: linear;
  transition-duration: .15s;
  transition-property: opacity, filter, max-width;

  .hamburger-box {
    position: relative;
    display: inline-block;
    width: 1.4rem;
    height: 1.25rem;
    transition: width 0.1s ease-out;
  }

  .hamburger-inner,
  .hamburger-inner::after,
  .hamburger-inner::before {
    position: absolute;
    width: 100%;
    height: 0.15rem;
    transition-timing-function: ease;
    transition-duration: .15s;
    transition-property: transform, width;
    border-radius: 999px;
    background-color: white;
  }

  .hamburger-inner {
    top: 50%;
    display: block;
    margin-top: -0.125rem;
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before,
    &::after {
      display: block;
      content: "";
    }

    &::before {
      top: -0.4375rem;
      transition: top 0.1s 0.25s ease-in,
                  opacity 0.1s ease-in;
    }

    &::after {
      bottom: -0.4375rem;
      transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
    }
  }

  ${props => props.isActive && `
    max-width: 1.5rem !important;
    max-height: 1.5rem !important;

    .hamburger-box {
      position: relative;
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
    }

    .hamburger-inner {
      transition-delay: .12s;
      transition-timing-function: cubic-bezier(.215,.61,.355,1);
      transform: rotate(225deg);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.1s ease-out,
                    opacity 0.1s 0.12s ease-out,
                    width 0.1s ease-out;
      }

      &::after {
        bottom: 0;
        transition: bottom .1s ease-out,
                    transform .22s cubic-bezier(.215,.61,.355,1) .12s,
                    width 0.1s ease-out;
        transform: rotate(-90deg);
      }
    }

    .hamburger-inner,
    .hamburger-inner::after,
    .hamburger-inner::before {
      width: 1.5rem;
    }
  `}
`

export default function HamburgerIcon({ isActive }) {
  return (
    <Wrapper isActive={isActive}>
      <span className="hamburger-box">
        <span className="hamburger-inner"></span>
      </span>
    </Wrapper>
  )
}
