import { useState, useEffect } from "react"

const useReducedMotion = () => {
  const [shouldReduceMotion, setShouldReduceMotion] = useState(false)

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-reduced-motion: reduce)")
    setShouldReduceMotion(mediaQuery.matches)
    const handleMedia = (e) => setShouldReduceMotion(e.matches)
    if (typeof mediaQuery.addEventListener === "function") {
      mediaQuery.addEventListener("change", handleMedia)
    } else {
      mediaQuery.addListener(handleMedia)
    }
    return () => {
      if (typeof mediaQuery.removeEventListener === "function") {
        mediaQuery.removeEventListener("change", handleMedia)
      } else {
        mediaQuery.removeListener(handleMedia)
      }
    }
  }, [])

  return shouldReduceMotion
}

export default useReducedMotion
