import isProd from "./is-prod"

if (typeof window !== "undefined") {
  require("whatwg-fetch")
}

export default function getChitChatMessages({ lang, size = 15, after }) {
  const prod = isProd()
  const url = prod ?
    `/.netlify/functions/get_chitchat_messages?lang=${lang}&size=${size}${after ? `&after=${after}` : ""}` :
    `/assets/chitchat-messages/${lang}.json`

  return window.fetch(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
  })
    .then(res => res.json())
    .then((res) => ({
      ...res,
      data: res.data.map(({ ref, data }) => ({
        ...data,
        id: ref["@ref"].id + (prod ? "" : Date.now()),
      }))
    }))
}
