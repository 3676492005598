import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import { useModal } from "../../hooks/modal"
import { useStore } from "../../hooks/store"
import getUserAgent from "../../utils/get-user-agent"
import useReducedMotion from "../../hooks/reducedMotion"

import closeIcon from "../../images/close-plaster.svg"
import blackAudioDescIcon from "../../images/svt_audiodesc_black.svg"

import ModalWrapper from "./ModalWrapper"
import VideoPlayer from "../VideoPlayer"
import AudioDescriptionButton from "../AudioDescriptionButton"

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.9);
  animation: video-modal-slide-up 0.3s forwards;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0, 0, 0, 0.85);
    backdrop-filter: blur(10px);
  }

  @keyframes video-modal-slide-up {
    0%    { transform: translateY(100%); }
    100%  { transform: translateY(0); }
  }
`

const Header = styled.header`
  position: relative;
  z-index: 10;
  display: flex;
  align-items: center;
  min-height: 2.625rem;
  padding: 0.25rem 3rem 0.25rem 0.75rem;

  h2 {
    margin: 0;
    padding-top: 0.125rem;
    font: 1rem/1 ${props => props.theme.headingFont};
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 0.0625rem;
  right: 0.125rem;
  width: 2.5rem;
  height: 2.5rem;
  border: 0;
  padding: 0;
  background: none;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Content = styled.div`
  position: absolute;
  top: 2.625rem;
  left: 0;
  display: flex;
  width: 100%;

  ${props => props.hide && `
    display: none;
  `}

  ${props => props.disabled ? `
    padding-top: 2rem;
    padding-bottom: 1rem;
  ` : `
    height: calc(100% - 3.625rem);

    @media (orientation: portrait) {
      align-items: center;
    }
  `}

  > div {
    width: 100%;
  }
`

const AudioDescAlert = styled.div`
  position: fixed;
  bottom: 17.5%;
  left: 50%;
  transform: translateX(-50%);
  height: 2rem;
  padding: 0 1rem 0 2.8125rem;
  border-radius: 1rem;
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 2rem;
  white-space: nowrap;
  background: white url(${blackAudioDescIcon}) no-repeat 1rem center;
  background-size: 1.25rem;

  ${props => props.animate && `
    animation: audio-desc-alert-fade-in-out 3.9s forwards;

    @keyframes audio-desc-alert-fade-in-out {
      0%    { opacity: 0; }
      8%    { opacity: 0; }
      16%   { opacity: 1; }
      92%   { opacity: 1; }
      100%  { opacity: 0; }
    }
  `}
`

export default function VideoPlayerModal({ lang, id, title, provider = "youtube", audioDescription, start, end, videos }) {
  const intl = useIntl()
  const { closeModal } = useModal()
  const [store, updateStore] = useStore()
  const [showAudioDescConfirm, setShowAudioDescConfirm] = useState(false)
  const [showAudioDescAlert, setShowAudioDescAlert] = useState(false)
  const ua = getUserAgent()
  const componentIsMounted = useRef(true)
  const hasAudioDesc = audioDescription || (videos && videos.find((x) => x.audio_description))
  const playlist = videos ? videos.map((video) => ({
    ...video,
    id: store.audioDescriptionEnabled && video.audio_description ? video.audio_description : video.id,
  })) : []

  const shouldReduceMotion = useReducedMotion()

  useEffect(() => {
    setShowAudioDescAlert(store.audioDescriptionEnabled)

    if (store.audioDescriptionEnabled) {
      setTimeout(() => {
        // Make sure the component is still mounted
        if (componentIsMounted.current) {
          setShowAudioDescAlert(false)
        }
      }, 4000)
    }
  }, [store.audioDescriptionEnabled])

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  return (
    <ModalWrapper standardUi={false}>
      <Wrapper>
        <Header>
          {hasAudioDesc &&
            <AudioDescriptionButton
              showConfirm={showAudioDescConfirm}
              onSetConfirm={setShowAudioDescConfirm}
            />
          }
          <h2>{videos && videos.length > 1 ? `${intl.formatMessage({ id: "playlist" })}: ${title}` : title}</h2>
          <CloseButton onClick={closeModal} aria-label={intl.formatMessage({ id: "close" })}>
            <img src={closeIcon} alt="" role="presentation" />
          </CloseButton>
        </Header>
        <Content hide={ua.isMobile && showAudioDescConfirm} disabled={!store.cookies[provider]}>
          {videos ?
            (videos.length > 1 ?
              <VideoPlayer
                lang={lang}
                title={title}
                provider={provider}
                playlist={playlist}
              />
            :
              <VideoPlayer
                lang={lang}
                id={videos[0].id}
                title={videos[0].title}
                provider={provider}
                audioDescription={videos[0].audioDescription}
              />
            )
          :
            <VideoPlayer
              lang={lang}
              id={id}
              title={title}
              provider={provider}
              audioDescription={audioDescription}
              start={start}
              end={end}
            />
          }
        </Content>
        {showAudioDescAlert && hasAudioDesc &&
          <AudioDescAlert animate={!shouldReduceMotion && store.animationsEnabled}>
            {intl.formatMessage({ id: "audioDescriptionIsActive" })}
          </AudioDescAlert>
        }
      </Wrapper>
    </ModalWrapper>
  )
}
