import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import CloseIcon from "./icons/Close"

import getRoomLink from "../utils/get-room-link"

import roomBlock1 from "../images/room-block-1.svg"
import roomBlock2 from "../images/room-block-2.svg"
import roomBlock3 from "../images/room-block-3.svg"
import roomBlock4 from "../images/room-block-4.svg"
import roomBlock5 from "../images/room-block-5.svg"
import roomBlock6 from "../images/room-block-6.svg"
import roomBlock7 from "../images/room-block-7.svg"
import roomBlock8 from "../images/room-block-8.svg"

const Nav = styled.nav`
  padding-top: 5.125rem;
`

const NavList = styled.ul`
  position: relative;
  list-style: none;
  margin: 0;
`

const NavListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 0.8125rem;
  padding-right: 0.8125rem;
  text-align: right;
`

const NavListItemLink = styled(Link)`
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 0rem 0 1rem;
  font: 1.125rem/1.2 ${props => props.theme.headingFont};
  color: white;
  text-decoration: none;

  /* border-radius: 2rem;
  background: rgba(0,0,0,0.3); */
  /* height: 2.5rem;
  margin-bottom: 1rem; */

  span {
    position: relative;
    margin-right: -2rem;
    padding-right: 2rem;

    padding: 0.5em 2rem 0.5rem 1.5rem;
    /* background: rgba(0,0,0,0.5); */
    border-radius: 2rem;

    transition: all .3s ease-in-out;

    :after {
      content: "";
      position: absolute;
      right: 0;
      height: 2px;
      display: block;
      background: white;
      width: 0%;
      transition: width 0.3s ease-in;
      margin-left: 1rem;
    }
  }

  &:hover,
  &:focus,
  &.active {
    color: white;

    span:after {
      width: calc(100% - 1rem);
    }
    img,
    :after {
      transform: scale(1.06);
    }
  }

  img {
    position: relative;
    display: block;
    width: 3.125rem;
    height: 3.4375rem;
    margin-left: 0.9375rem;
    transition: all 125ms ease-in-out;
  }

  &.active {
    span {
      color: black;
      background: rgba(255,255,255,0.8);
    }

    span::after {
      width: 0;
    }
  }

  @media screen and (max-height: 620px) {
    img {
      width: 2.8rem;
      height: 2.9rem;
    }

    &.active:after {
      width: 3.2rem;
      height: 3.2rem;
    }
  }
`

const CloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 0.8rem;
  width: 3rem;
  height: 3rem;
  border: 0;
  padding: 0;
  background: none;

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const RoomMenu = ({ lang, onClose, room }) => {
  const intl = useIntl()

  const navListItems = () => {
    const imgs = {
      1: roomBlock1,
      2: roomBlock2,
      3: roomBlock3,
      4: roomBlock4,
      5: roomBlock5,
      6: roomBlock6,
      7: roomBlock7,
      8: roomBlock8
    }

    return Object.keys(imgs).map((key) => (
      <NavListItem key={key}>
        <NavListItemLink to={getRoomLink(key, lang)} className={key == room ? "active" : null}>
          <span>{intl.formatMessage({ id: `roomHeading${key}` })}</span>
          <img src={imgs[key]} role="presentation" />
        </NavListItemLink>
      </NavListItem>
    ))
  }

  return (
    <Nav aria-label={intl.formatMessage({ id: "mainMenu" })} onClick={onClose}>
      <CloseButton onClick={onClose} aria-label={intl.formatMessage({ id: "close" })}>
        <CloseIcon />
      </CloseButton>
      <NavList>
        {navListItems()}
      </NavList>
    </Nav>
  )
}

export default RoomMenu
