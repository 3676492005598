import React, { useState, useEffect } from "react"
import styled from "styled-components"

import RoomContentScroller from "./RoomContentScroller"
import AnswerButton from "./QuestionPanelAnswerButton"
import EmailLink from "./QuestionPanelEmailLink"
import questionMark from "../images/icons/BalooQuestionMark_black.svg"

const colorsTeen = {
  sandbrown: "222,204,164",
  goldbrown: "196,151,90",
  turqoise: "42,143,140",
  lila: "147,98,140",
  blue: "34,126,174",
  oldrose: "194,100,119",
  gray: "153,153,153",
  darkgray: "58,58,58",
  cyan: "8, 187, 239",
  pink: "236, 96, 159",
  limegreen: "162, 198, 23",
  orange: "255, 120, 0",
}


const QuestionWrapper = styled.button`
  position: relative;
  display: flex;
  width: 100%;
  min-height: 7rem;
  border: 0;
  border-radius: 0.3125rem;
  white-space: normal;
  box-shadow: ${props => props.theme.shadows.flat};
  background-size: 100% 150%;
  background-position: top center;
  transition:
    background-position .3s ease-in-out,
    transform .3s ease-in-out;

  ::before {
    content: "";
    background: url(${questionMark}) no-repeat;
    background-position: center center;
    background-size: 100%;
    position: absolute;
    display: block;
    height: 100%;
    padding-left: 100%;
    top: 0;
    opacity: .08;
    mix-blend-mode: color-burn;
  }

  ${props => props.themeName === "dark" ? `
    padding: 0.6875rem 0.75rem;
    background-image: linear-gradient(180deg, #666666 0%, #333333 100%);
    box-shadow: ${props => props.theme.shadows.inset};
    vertical-align: top;
    text-align: left;

    &.lila {background: rgb(${colorsTeen.lila})}
    &.blue {background: rgb(${colorsTeen.blue})}
    &.turqoise {background: rgb(${colorsTeen.turqoise})}
    &.oldrose {background: rgb(${colorsTeen.oldrose})}
    &.goldbrown {background: rgb(${colorsTeen.goldbrown})}

  ` : `
    align-items: center;
    padding: 1rem 0.5rem;

    &.blue {
      background-image: linear-gradient(180deg, rgba(111, 205, 245, 1) 0%, rgba(8, 182, 233, 1) 100%);
      color: rgb(31, 110, 143);
    }

    &.pink {
      background-image: linear-gradient(180deg, rgba(241, 138, 184, 1) 0%, rgba(237, 98, 160, 1) 100%);
      color: rgb(161, 38, 93);
    }

    &.green {
      background-image: linear-gradient(180deg, rgba(198, 214, 82, 1) 0%, rgba(162, 197, 24, 1) 100%);
      color: rgb(106, 126, 19);
    }

    &.orange {
      background-image: linear-gradient(180deg, rgba(248, 168, 83, 1) 0%, rgba(243, 120, 9, 1) 100%);
      color: #9A4D07;
    }
  `}

  &::after {
    content: "";
    position: absolute;
    top: ${props => props.themeName === "dark" ? "calc(100% + 1px)" : "100%"};
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -0.625rem;
    border-left: 0.625rem solid transparent;
    border-right: 0.625rem solid transparent;
    border-top: 0.625rem solid #333;
    opacity: 0;
    transition: opacity 0.3s ease;
  }


  &:hover,
  &.is-active {
    background-position: bottom center;
    transform: rotate(-5deg);
  }

  &.is-active::after {
    opacity: 1;
  }
`

const QuestionText = styled.span`
  margin: 0;
  text-transform: uppercase;
  font-family: ${props => props.theme.headingFont};
  color: white;
  text-shadow: 0 0 5px rgba(0,0,0,0.6);

  ${props => props.themeName === "dark" ? `
    font-size: 0.98rem;
    line-height: 120%;
    font-weight: bold;
    text-shadow:
      0 3px 4px rgba(0, 0, 0, 0.25),
      0.5px 0.5px 0 rgba(0, 0, 0, 0.5),
      1px 1px 0 rgba(0, 0, 0, 0.25);
  ` : `
    font-weight: 900;
    line-height: 110%;
    text-align: center;
  `}
`

export default function QuestionPanel({ lang, emailContactImage, questions, theme = "" }) {
  const filteredQuestions = questions.filter((n) => n.answers && n.answers.length)
  const [subItems, setSubItems] = useState([])
  const [activeItemIndex, setActiveItemIndex] = useState(-1)
  const getFontSize = (question) => ((20 - ((question.length - 22) * 0.1)) / 16) + "rem"
  const getColor = (idx) => {
    const colors = theme === "dark" ?
      ["lila", "blue", "turqoise", "oldrose", "goldbrown"] :
      ["blue", "pink", "green", "orange"]
    return colors[idx % colors.length]
  }

  useEffect(() => {
    if (activeItemIndex === -1) {
      setSubItems([])
    } else {
      setSubItems(filteredQuestions[activeItemIndex].answers || [])
    }
  }, [activeItemIndex])

  return (
    <RoomContentScroller
      items={filteredQuestions.map((question, index) => (
        <QuestionWrapper
          themeName={theme}
          className={`${getColor(index)} ${index === activeItemIndex ? "is-active" : ""}`}
          onClick={() => setActiveItemIndex(activeItemIndex === index ? -1 : index)}
          aria-expanded={activeItemIndex === index}
        >
          <QuestionText
            id={`question-panel-q-${index}`}
            style={theme === "dark" ? {} : { fontSize: getFontSize(question.question) }}
            themeName={theme}
          >
            {question.question}
          </QuestionText>
        </QuestionWrapper>
      ))}
      subItems={subItems.length === 0 ? [] : subItems.map((item) => (
        <AnswerButton
          lang={lang}
          question={activeItemIndex > -1 && filteredQuestions[activeItemIndex].question}
          activeItemIndex={activeItemIndex}
          item={item}
          videoTitle={activeItemIndex > -1 ? `${filteredQuestions[activeItemIndex].question} ${item.name}` : ""}
        />
      )).concat(
        <EmailLink image={emailContactImage} />
      )}
      subItemShape="circle"
      activeItemIndex={activeItemIndex}
    />
  )
}
