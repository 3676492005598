import React from "react"

export default function SettingsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      width="22"
      viewBox="0 0 24 24"
    >
      <path
        fill="#fff"
        d="M2.4 14.4h.829a1.2234 1.2234 0 011.1295.753l.0074.0178a1.221 1.221 0 01-.2637 1.3329l-.5875.5875a2.4 2.4 0 103.3942 3.3941l.5852-.5852a1.2256 1.2256 0 011.338-.2648l.0165.0069A1.2207 1.2207 0 019.6 20.7693V21.6c0 .6365.2529 1.247.7029 1.6971a2.4005 2.4005 0 003.3942 0A2.4005 2.4005 0 0014.4 21.6v-.8294a1.2213 1.2213 0 01.7536-1.128l.0177-.0074a1.2256 1.2256 0 011.3354.2656l.5845.5845a2.3998 2.3998 0 004.097-1.6971c0-.6365-.2528-1.247-.7029-1.697l-.5845-.5845a1.2248 1.2248 0 01-.2655-1.3354l.0073-.0177a1.2209 1.2209 0 011.128-.7536H21.6c.6365 0 1.247-.2529 1.6971-.7029a2.4005 2.4005 0 000-3.3942A2.4003 2.4003 0 0021.6 9.6h-.8291a1.223 1.223 0 01-1.1293-.753l-.0075-.0178a1.2209 1.2209 0 01.2638-1.333l.5874-.5874a2.3993 2.3993 0 00.5203-2.6155 2.3992 2.3992 0 00-1.2989-1.2988 2.4003 2.4003 0 00-2.6155.5202l-.5852.5852a1.2257 1.2257 0 01-1.338.2648l-.0165-.007a1.2206 1.2206 0 01-.7515-1.127V2.4A2.4003 2.4003 0 0012 0a2.4002 2.4002 0 00-2.4 2.4v.8294a1.221 1.221 0 01-.7536 1.128l-.0203.0084a1.221 1.221 0 01-1.3308-.2646l-.5865-.5865a2.4 2.4 0 00-3.3941 3.3941l.5845.5845a1.2253 1.2253 0 01.2656 1.3354l-.0074.0177a1.221 1.221 0 01-1.128.7536H2.4a2.4001 2.4001 0 00-1.697 4.0971c.45.45 1.0605.7029 1.697.7029zM12 8.2667a3.733 3.733 0 013.4492 2.3046A3.7334 3.7334 0 1112 8.2667z"
      />
    </svg>
  )
}
