import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import RoomContentScroller from "./RoomContentScroller"
import ArrowBandaid from "./icons/ArrowBandaid"

import getSlugFromString from "../utils/get-slug-from-string"

import faqButtonBefore from "../images/faq-covers/Cover_FAQ_Barn_1_Sve.svg"
import faqButtonBeforeEng from "../images/faq-covers/Cover_FAQ_Barn_1_Eng.svg"
import faqButtonDuring from "../images/faq-covers/Cover_FAQ_Barn_2_Sve.svg"
import faqButtonDuringEng from "../images/faq-covers/Cover_FAQ_Barn_2_Eng.svg"
import faqButtonAfter from "../images/faq-covers/Cover_FAQ_Barn_3_Sve.svg"
import faqButtonAfterEng from "../images/faq-covers/Cover_FAQ_Barn_3_Eng.svg"
import faqButtonAbout from "../images/faq-covers/Cover_FAQ_Barn_4_Sve.svg"
import faqButtonAboutEng from "../images/faq-covers/Cover_FAQ_Barn_4_Eng.svg"
import base64svg from "../components/icons/base64svg"
import pictoBefore from "../images/icons/Picto_Vard1.svg"
import pictoDuring from "../images/icons/Picto_Vard2.svg"
import pictoAfter from "../images/icons/Picto_Vard3.svg"
import pictoBody from "../images/icons/Picto_Kropp.svg"

const arrowBandaid = base64svg(<ArrowBandaid dir="right" color="gray"/>)

const CategoryButton = styled(Link)`
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  display: block;
  ${props => props.theme.animations.transitionBase};

  ::after {
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 1.5rem;
    background: radial-gradient(closest-side, rgba(0,0,0,0.2), rgba(0,0,0,0));
    z-index: -1;
    transform: translateY(70%);
    ${props => props.theme.animations.transitionBase};
  }

  :hover {
    transform: translateY(-0.3rem);

    ::after {
      transform: translateY( calc(70% + 0.5rem) );
    }
  }

`

const BalloonImg = styled.img`
  display: block;
  width: 100%;
  height: auto;
`

const CategoryButton13 = styled(Link)`
  position: relative;
  display: block;
  margin-bottom: 0.375rem;
  padding: 0;
  border: 0;
  width: 100%;
  padding-bottom: 62.5%;
  border-radius: 5px;
  overflow: hidden;
  background: rgba(255,0,0,0.1);
  will-change: transform, box-shadow;
  /* filter: drop-shadow(${props => props.theme.shadows.filter.flat}); */
  box-shadow: ${props => props.theme.shadows.flat};
  ${props => props.theme.animations.transitionBase}

  :hover {
    transform: translateY(-0.1875rem);
    box-shadow: ${props => props.theme.shadows.high};
    /* filter: drop-shadow(${props => props.theme.shadows.filter.high}); */
  }
`

const FaqBox = styled.div`
  /* height: 62.5%; */
  /* padding-bottom: 62.5%; */
  background: white;
  height: 100%;
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  line-height: 1.1;

  ::before {
    content: "";
    background-image: url(${ props => props.icon});
    background-origin: content-box;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: 80%;
    flex-basis: 30%;
    flex-grow: 0;
    padding: .6em;
    box-sizing: border-box;
  }

  &.before,
  &.during,
  &.after,
  &.body {
    color: ${ props =>props.color};

    ::before {background-color: ${ props =>props.color};}
  }

  ::after {
    display: block;
    content: url(${arrowBandaid});
    position: absolute;
    bottom: 0;
    right: .4em;
    width: 20px;
  }

  .inner {
    flex-grow: 0;
    flex-basis: 70%;
    padding: .6em .4em .6em .6em;
    white-space: initial;
    top: 0;
    bottom: 0;
    color: initial;
  }

  strong {
    display: block;
    margin: 0;
    font-size: 1.4rem;
    line-height: 1.2;
    font-family: ${props => props.theme.headingFont};
    color: ${ props =>props.color};
  }
`

export default function FaqSection({ lang, colors, room }) {
  const intl = useIntl()
  const isEng = lang !== "sv"
  const is13Plus = room === 7

  const getLink = (category) => `/${lang}/faq${is13Plus ? "-13" : ""}/${getSlugFromString(category)}`

  const before = intl.formatMessage({ id: "before" })
  const during = intl.formatMessage({ id: "during" })
  const after = intl.formatMessage({ id: "after" })
  const aboutTheBody = intl.formatMessage({ id: "aboutTheBody" })
  const beforeTitleArray = intl.formatMessage({ id: "beforeAnaesthesiaAndSurgery" }).split(" ")
  const duringTitleArray = intl.formatMessage({ id: "duringAnaesthesiaAndSurgery" }).split(" ")
  const afterTitleArray = intl.formatMessage({ id: "afterAnaesthesiaAndSurgery" }).split(" ")

  const itemsChildren = [
    <CategoryButton to={getLink(before)}>
      <BalloonImg
        src={isEng ? faqButtonBeforeEng : faqButtonBefore}
        alt={before}
      />
    </CategoryButton>,
    <CategoryButton to={getLink(during)}>
      <BalloonImg
        src={isEng ? faqButtonDuringEng : faqButtonDuring}
        alt={during}
      />
    </CategoryButton>,
    <CategoryButton to={getLink(after)}>
      <BalloonImg
        src={isEng ? faqButtonAfterEng : faqButtonAfter}
        alt={after}
      />
    </CategoryButton>,
    <CategoryButton to={getLink(aboutTheBody)}>
      <BalloonImg
        src={isEng ? faqButtonAboutEng : faqButtonAbout}
        alt={aboutTheBody}
      />
    </CategoryButton>,
  ]

  const items13plus = [
    <CategoryButton13 to={getLink(before)}>
      <FaqBox icon={pictoBefore} className="before" color={colors && colors.hex.turqoise}>
        <div className="inner">
          <span>
            <strong>{beforeTitleArray[0]}</strong>
            {beforeTitleArray.slice(1).join(" ")}
          </span>
        </div>
      </FaqBox>
    </CategoryButton13>,
    <CategoryButton13 to={getLink(during)}>
      <FaqBox icon={pictoDuring} className="during" color={colors && colors.hex.lila}>
        <div className="inner">
          <span>
            <strong>{duringTitleArray[0]}</strong>
            {duringTitleArray.slice(1).join(" ")}
          </span>
        </div>
      </FaqBox>
    </CategoryButton13>,
    <CategoryButton13 to={getLink(after)}>
      <FaqBox icon={pictoAfter} className="after" color={colors && colors.hex.blue}>
        <div className="inner">
          <span>
            <strong>{afterTitleArray[0]}</strong>
            {afterTitleArray.slice(1).join(" ")}
          </span>
        </div>
      </FaqBox>
    </CategoryButton13>,
    <CategoryButton13 to={getLink(aboutTheBody)}>
      <FaqBox icon={pictoBody} className="after" color={colors && colors.hex.goldbrown}>
        <div className="inner">
          <span>
            <strong>{aboutTheBody}</strong>
          </span>
        </div>
      </FaqBox>
    </CategoryButton13>,
  ]

  return (
    <RoomContentScroller
      items={is13Plus ? items13plus : itemsChildren}
      shape={is13Plus ? null : "balloon"}
    />
  )
}
