import React from "react"

export default function AccessibilityIcon({ fill = "currentColor" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 32.5 40"
      fill={fill}
    >
      <g>
        <path d="M20.5,8.75a4.38,4.38,0,0,1-4.29-5.23,4.32,4.32,0,0,1,1.2-2.24A4.39,4.39,0,0,1,19.65.08a4.43,4.43,0,0,1,2.52.25A4.38,4.38,0,0,1,20.5,8.75Z" transform="translate(-4.25 0)" />
        <path d="M34.25,8.81h-.07L34,8.9c-1.46.43-8.51,2.42-13.48,2.42-4.62,0-11-1.72-13.09-2.31l-.63-.2c-1.48-.39-2.5,1.12-2.5,2.5s1.23,2,2.47,2.48v0l7.43,2.33c.76.29,1,.58,1.07.84a7.52,7.52,0,0,1,0,3l-.45,3.52L12.22,37.3a.36.36,0,0,1,0,.11l0,.1A2.25,2.25,0,0,0,14.68,40c1.54,0,2.21-1.06,2.5-2.5s2.19-12.31,3.29-12.31S23.81,37.5,23.81,37.5c.29,1.44,1,2.5,2.5,2.5a2.25,2.25,0,0,0,2.5-2.5,1.7,1.7,0,0,0-.06-.31L26.2,23.54,25.75,20c-.33-2.05-.06-2.72,0-2.88h0a2.81,2.81,0,0,1,1.37-.85l7-2.43.13,0c1.25-.46,2.5-1.11,2.5-2.49S35.73,8.42,34.25,8.81Z" transform="translate(-4.25 0)" />
      </g>
    </svg>
  )
}
