function getYouTubeIdFromUrl(url) {
  if (url.indexOf("http") === -1) {
    return url.trim()
  }

  const arr = url.split("v=")

  if (arr.length === 1) {
    if (url.indexOf("youtu.be/") > -1) {
      return url.split("youtu.be/")[1].split("?")[0].trim()
    }

    return url.trim()
  }

  return arr[1].split("&")[0].trim()
}

export default getYouTubeIdFromUrl
