import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import ReactTimeAgo from "react-time-ago"

import Logo from "./LogoFull"
import ChitChatAvatarIcon from "./ChitChatAvatarIcon"

const Wrapper = styled.div`
  padding-top: 1.0625rem;

  button[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @keyframes chitchat-placeload {
    0% {
      background-position: 60% 0;
    }
    100% {
      background-position: -60% 0;
    }
  }

  .loads {
    animation: chitchat-placeload 1s linear infinite forwards;
    background: #eee;
    background: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
    background-size: 1200px 100px;
  }
`

const ChatBubble = styled.div`
  position: relative;
  width: calc(100% - 3.75rem);
  min-height: 4.1875rem;
  margin-bottom: 1rem;
  padding: 0.625rem;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.25));

  ::before {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
  }

  ${props => props.reply ? `
    left: 3.75rem;
    ${!props.cms && `margin-top: -1.625rem;`}
    border-radius: 0.3125rem 0 0.3125rem 0.3125rem;
    background: #DAE4E5;

    ::before {
      right: -0.625rem;
      border-right: 0.625rem solid transparent;
      border-top: 0.625rem solid #DAE4E5;
    }
  ` : `
    padding-bottom: 1.125rem;
    border-radius: 0 0.3125rem 0.3125rem 0.3125rem;
    background: white;

    ::before {
      left: -0.625rem;
      border-left: 0.625rem solid transparent;
      border-top: 0.625rem solid white;
    }
  `}

  &.loads::before {
    border-top-color: #eee;
  }

  p,
  time {
    margin: 0;
    font-size: 0.875rem;
  }

  time {
    display: block;
    text-align: right;
    color: #555;
    font-size: 0.75rem;
  }
`

const Username = styled.span`
  position: absolute;
  top: -1.125rem;
  left: 2.1875rem;
  font-weight: 600;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.5);

  ${props => props.isPlaceholder && `
    filter: blur(3px);
  `}
`

const ProfilePic = styled.span`
  position: absolute;
  top: -1.0625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5625rem;
  height: 1.5625rem;
  border-radius: 50%;
  background: white;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.25);

  ${props => props.reply ? `
    right: 0.25rem;
  ` : `
    left: 0.25rem;
  `}
`

const StyledLogoFull = styled(Logo)`
  width: 1.125rem;
  height: 1.125rem;
`

export default function ChitChatBubble({ message, lang, placeholder = false, getCmsControls = null, ...props }) {
  const intl = useIntl()

  return (
    <Wrapper aria-hidden={placeholder} {...props}>
      <ChatBubble className={placeholder ? "loads" : ""}>
        <ProfilePic className={placeholder ? "loads " : ""}>
          {message &&
            <ChitChatAvatarIcon
              color={message.color}
              avatar={message.avatar}
            />
          }
        </ProfilePic>
        <p>
          <Username isPlaceholder={placeholder}>{message ? message.username : "Username"}</Username>
          {!placeholder &&
            <>
              <ReactTimeAgo date={new Date(message.timestamp)} locale={lang} />
              {message.message}
            </>
          }
        </p>
        {getCmsControls && getCmsControls(message)}
      </ChatBubble>
      {message && message.reply &&
        <ChatBubble reply cms={!!getCmsControls}>
          <ProfilePic reply>
            <StyledLogoFull
              lang={lang}
              mini
              room={8}
            />
          </ProfilePic>
          <p>
            <span className="structural">{intl.formatMessage({ id: "siteTitle" })}</span>
            <ReactTimeAgo date={new Date(message.reply_timestamp)} locale={lang} />
            {message.reply}
          </p>
          {getCmsControls && getCmsControls(message, true)}
        </ChatBubble>
      }
    </Wrapper>
  )
}
