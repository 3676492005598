import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import PlayIcon from "./icons/Play"
import PauseIcon from "./icons/Pause"

import secondsToTime from "../utils/seconds-to-time"

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100px;
  height: 2.5rem;
  padding: 10px;
  text-align: center;
  border-radius: 10px;
  background: black;
`

const Button = styled.button`
  position: absolute;
  top: 0.25rem;
  left: 0.5rem;
  padding: 0.4375rem;
  border: 0;
  background: black;

  svg {
    display: block;
    width: 1.125rem;
  }
`

const CurrentTime = styled.div`
  margin-left: 2.5px;
  padding: 0 5px;
  color: white;
  font-size: 14px;
  font-weight: bold;
  font-family: Avenir, "Avenir Next", "Helvetica Neue", "Segoe UI", Helvetica, Arial, sans-serif;
`

const Placeholder = styled.span`
  letter-spacing: 0.1875rem;
`

export default function CiceronAudioPlayer({ toggle, audioPlaying, duration = 0, currentTime = 0, ...props }) {
  const intl = useIntl()

  return (
    <Wrapper {...props}>
      <Button
        onClick={toggle}
        aria-label={`${intl.formatMessage({ id: audioPlaying ? "pause" : "play" })} ${intl.formatMessage({ id: "sound" })}`}
      >
        {audioPlaying ? <PauseIcon /> : <PlayIcon />}
      </Button>
      <CurrentTime aria-label={intl.formatMessage({ id: "currentTime" })}>
        {duration > 0 ?
          secondsToTime(Math.floor(duration - currentTime))
        :
          <Placeholder>--:--</Placeholder>
        }
      </CurrentTime>
    </Wrapper>
  )
}
