import React, { useState, useCallback, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import Alert from "./Alert"

import trackEvent from "../utils/track-event"

import shareIcon from "../images/social-share.svg"
import shareFacebookIcon from "../images/share-facebook-icon.svg"
import shareTwitterIcon from "../images/share-twitter-icon.svg"
import shareLinkedInIcon from "../images/share-linkedin-icon.svg"
import shareEmailIcon from "../images/share-email-icon.svg"
import shareLinkIcon from "../images/share-link-icon.svg"

const Wrapper = styled.div`
  position: relative;
  z-index: ${props => (props.expanded ? "1000" : "auto")};
  display: inline-block;
  width: 2rem;
  height: 2rem;
`

const Toggle = styled.button`
  width: 100%;
  height: 100%;
  padding: 0.5rem;
  border: 0;
  border-radius: 50%;
  background: #e20000;

  img {
    position: relative;
    left: 1px;
    display: block;
    width: 100%;
  }
`

const Dropdown = styled.div`
  overflow: auto;
  position: absolute;
  width: 12.5rem;
  max-height: 17.125rem;
  background: #fff;
  border-radius: 1rem;
  bottom: calc(100% + 1rem);
  left: 50%;
  margin: 0.5rem 0 0 0;
  color: ${props => props.theme.textColor};
  transform: translateX(-50%);
  box-shadow: ${props => props.theme.shadows.flat};
  display: none;

  &.expanded {
    display: initial;
  }

  > h2 {
    margin-top: 0;
    padding-top: 0.8rem;
    padding-bottom: 0.5rem;
    font: 1rem/1.1 ${props => props.theme.headingFont};
    border-bottom: 1px solid rgba(209, 209, 209, 0.5);
    text-align: center;
    background: rgba(209, 209, 209, 0.5);
  }
`

const OptionsList = styled.ul`
  list-style: none;
  margin: 0.4rem 0;
  padding: 0;
`

const Option = styled.li`
  width: 100%;
  margin: 0;
`

const ShareLink = styled.a`
  display: block;
  padding: 0.5rem 0.75rem;
  color: inherit;
  text-decoration: inherit;
  text-align: left;

  &:hover {
    background: rgba(209, 209, 209, 0.5);
  }
`

const ShareText = styled.span`
  font-size: 0.875rem;
  font-family: ${props => props.theme.secondaryFont};
  font-style: normal;
  font-weight: normal;
  display: inline;
`

const StyledShareIcon = styled.img`
  width: 1.5625rem;
  height: 1.5625rem;
  margin-right: 0.5rem;
  vertical-align: middle;
`

const CopyButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5rem 0.75rem;
  border: 0;
  background: none;

  &:hover {
    background: rgba(209, 209, 209, 0.5);
  }

  img {
    display: inline-block;
    margin-right: 0.5rem;
  }
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.1);
`

const HiddenInput = styled.input`
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(1px,1px,1px,1px);
  padding: 0;
  border: 0;
`

let copySuccessMessageTimeout

const ShareWidget = ({ ...props }) => {
  const intl = useIntl()
  const [expanded, setExpanded] = useState(false)
  const [pageUrl, setPageUrl] = useState("")
  const [copySuccessMessage, setCopySuccessMessage] = useState("")

  const track = (name, action = "Link Click") => {
    trackEvent({ category: "Share Widget", action, name })
  }

  const toggleExpanded = () => {
    track(`${expanded ? "Close" : "Open"} Share Widget`, "Button Click")
    setExpanded(!expanded)
  }

  const closeDropdownOnEscape = useCallback(event => {
    if (event.keyCode === 27) {
      toggleExpanded()
    }
  }, [])

  useEffect(() => {
    setPageUrl(window.location.href)
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", closeDropdownOnEscape, false)
    return () => {
      document.removeEventListener("keydown", closeDropdownOnEscape, false)
    }
  }, [])

  useEffect(() => {
    if (copySuccessMessage) {
      copySuccessMessageTimeout = setTimeout(() => {
        setCopySuccessMessage("")
      }, 4000)
    }

    return () => {
      clearTimeout(copySuccessMessageTimeout)
    }
  }, [copySuccessMessage])

  const handleCopyLink = () => {
    const copyText = document.getElementById("page-link")
    copyText.focus()
    copyText.setSelectionRange(0, copyText.value.length)
    document.execCommand("copy")
    copyText.blur()
    track("Copy Link", "Button Click")
    toggleExpanded()
    setCopySuccessMessage(intl.formatMessage({ id: "linkCopied" }))
  }

  return (
    <Wrapper {...props} expanded={expanded}>
      {expanded && (
        <Backdrop onClick={toggleExpanded} />
      )}
      <Toggle
        aria-expanded={expanded}
        aria-controls="share-dropdown"
        onClick={toggleExpanded}
      >
        <img src={shareIcon} alt={intl.formatMessage({ id: "share" })} />
      </Toggle>
      <Dropdown id="share-dropdown" className={expanded && "expanded"}>
        {expanded &&
        <>
          <h2>{intl.formatMessage({ id: "share" })}</h2>
          <OptionsList >
            <Option>
              <ShareLink
                href={`https://www.facebook.com/sharer.php?u=${pageUrl}`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => track("Facebook")}
              >
                <StyledShareIcon src={shareFacebookIcon} alt="" role="presentation" />
                <ShareText>Facebook</ShareText>
              </ShareLink>
            </Option>
            <Option>
              <ShareLink
                href={`https://twitter.com/intent/tweet?text=${intl.formatMessage({ id: "siteTitle" })}%20-%20${pageUrl}`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => track("Twitter")}
              >
                <StyledShareIcon src={shareTwitterIcon} alt="" role="presentation" />
                <ShareText>Twitter</ShareText>
              </ShareLink>
            </Option>
            <Option>
              <ShareLink
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${pageUrl}`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => track("LinkedIn")}
              >
                <StyledShareIcon src={shareLinkedInIcon} alt="" role="presentation" />
                <ShareText>LinkedIn</ShareText>
              </ShareLink>
            </Option>
            <Option>
              <ShareLink
                href={`mailto:?subject=${intl.formatMessage({ id: "checkOutLink" })}%20${intl.formatMessage({ id: "siteTitle" })}!&body=${pageUrl}`}
                target="_blank"
                rel="noreferrer noopener"
                onClick={() => track("Email")}
              >
                <StyledShareIcon src={shareEmailIcon} alt="" role="presentation" />
                <ShareText>{intl.formatMessage({ id: "email" })}</ShareText>
              </ShareLink>
            </Option>
            <Option>
              <CopyButton onClick={handleCopyLink}>
                <StyledShareIcon src={shareLinkIcon} alt="" role="presentation" />
                <ShareText>{intl.formatMessage({ id: "copyLink" })}</ShareText>
              </CopyButton>
              <HiddenInput type="text" id="page-link" value={pageUrl} readOnly />
            </Option>
          </OptionsList>
          </>
          }
      </Dropdown>
      {copySuccessMessage &&
        <Alert message={copySuccessMessage} />
      }
    </Wrapper>
  )
}

export default ShareWidget
