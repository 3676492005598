import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { useModal } from "../../hooks/modal"
import ActionButton from "../ActionButton"
import CloseIcon from "../icons/Close"

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: auto;
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background-color: rgba(0, 0, 0, 0.55);
    backdrop-filter: blur(5px);
  }
`

const Container = styled.div`
  ${props => props.standardUi && `
    position: relative;
    margin: 2rem;
  `}

  &:focus {
    outline: 0;
  }
`

const Modal = styled.div`
  max-width: ${props => (props.maxWidth != null ? props.maxWidth : `auto`)};
  margin: 0 auto;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  background-image: linear-gradient(
    0deg,
    ${props => props.theme.red[3]} 0%,
    ${props => props.theme.red[2]} 100%
  );
  color: white;
  border-radius: 2.5rem 2.5rem 0 0;
`

const ModalHeaderTitle = styled.h2`
  margin: 0 0 0 2.5rem;
  font: normal 1.375rem/1.5 ${props => props.theme.headingFont};
  text-transform: uppercase;
`

const StyledActionButton = styled(ActionButton)`
  margin-right: 1rem;
`

const ModalBody = styled.div`
  background-color: white;
  border-radius: 0 0 2.5rem 2.5rem;
  overflow: hidden;
`

export default function ModalWrapper({ children, header, maxWidth, onClose = null, standardUi = true, ...props }) {
  const { closeModal } = useModal()
  const intl = useIntl()
  const modalRef = useRef()
  const [hasFocused, setHasFocused] = useState(false)

  const getModalElement = () => {
    // Workaround for IE11
    return modalRef.current || document.getElementById("modal")
  }

  useEffect(() => {
    function keyListener(e) {
      const listener = keyListenersMap.get(e.keyCode)
      return listener && listener(e)
    }

    document.addEventListener("keydown", keyListener)

    return () => document.removeEventListener("keydown", keyListener)
  })

  // Only allow elements in the modal to get focus
  const handleTabKey = (e) => {
    const focusableModalElements = getModalElement().querySelectorAll(
      `a[href], svg a, button:enabled, textarea, input[type="text"], input[type="radio"], input[type="checkbox"], select, iframe`
    )
    const firstElement = focusableModalElements[0]
    const lastElement = focusableModalElements[focusableModalElements.length - 1]

    if (!e.shiftKey && document.activeElement === lastElement) {
      firstElement.focus()
      return e.preventDefault()
    }

    if (e.shiftKey && document.activeElement === firstElement) {
      lastElement.focus()
      e.preventDefault()
    }

    setTimeout(() => {
      let insideModal

      focusableModalElements.forEach((el) => {
        if (document.activeElement === el) {
          insideModal = true
        }
      })

      if (!insideModal) {
        if (e.shiftKey) {
          lastElement.focus()
        } else {
          firstElement.focus()
        }
      }
    })
  }

  const keyListenersMap = new Map([[27, onClose || closeModal], [9, handleTabKey]])

  // Set focus on the modal when first opening the modal
  useEffect(() => {
    const modal = getModalElement()

    if (modal && !hasFocused) {
      setHasFocused(true)
      modal.focus()
    }
  })

  return (
    <Wrapper {...props}>
      {standardUi &&
        <Backdrop onClick={closeModal} />
      }
      <div role="dialog" aria-modal="true">
        <Container tabIndex="-1" id="modal" ref={modalRef} standardUi={standardUi}>
          {standardUi ?
            <Modal maxWidth={maxWidth}>
              {header &&
                <ModalHeader>
                  <ModalHeaderTitle>{header}</ModalHeaderTitle>
                  <StyledActionButton onClick={closeModal}>
                    <CloseIcon />
                    {intl.formatMessage({ id: "close" })}
                  </StyledActionButton>
                </ModalHeader>
              }
              <ModalBody>{children}</ModalBody>
            </Modal>
            :
            children
          }
        </Container>
      </div>
    </Wrapper>
  )
}
