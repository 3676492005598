import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ModalWrapper from "../modals/ModalWrapper"
import CloseIcon from "../icons/Close"

const Wrapper = styled.div`
  position: absolute;
  z-index: 50;
  width: 100%;
  height: 100%;
  overflow: hidden;
  animation: fadeIn 0.3s ease-in;

  [data-closes-wrapper] {
    cursor: crosshair;
  }

  &.active {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    align-content: flex-end;
  }

  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }

  @keyframes fadeOut {
    from {opacity: 1;}
    to {opacity: 0;}
  }
`

const Bg = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0,0,0,0.7);
  z-index: -1;

  @supports ((-webkit-backdrop-filter: blur(5px)) or (backdrop-filter: blur(5px))) {
    background: rgba(0,0,0,0.2);
    backdrop-filter: blur(${props => (props.blur ? props.blur : 5)}px);
  }
  cursor: crosshair;
`

const Constraint = styled.div`

  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
  max-height: 100%;
  ${props => props.alignContent && `display: flex; align-items: ${props.alignContent};`};
  ${props => props.flexBasis && `flex-basis: ${props.flexBasis};`}

  svg {
    display: block;
    margin: 0 auto;
  }
`

const ButtonClose = styled.button`
  position: absolute;
  top: .4rem;
  right: .4rem;
  z-index: 90;
  filter: drop-shadow(0px 0px 2px black);
  border: none;
  background: none;
  color: white;
  cursor: pointer;

  svg {
    display: block;
    margin: 0 auto;
  }

  @media (max-height: 550px) {
    right: 1.2rem;
  }

  &.inside {
    right: 0;
    top: 0;
  }
`

export default function MeoItemModal({ closeFn, customCloseButton, bgBlur, alignContent, flexBasis = null, ...props }) {
  const intl = useIntl()

  return(
    <ModalWrapper onClose={closeFn} standardUi={false}>
      <Wrapper className="active">
        <Bg onClick={closeFn} blur={bgBlur} />
        {customCloseButton ?
          customCloseButton :
          <ButtonClose onClick={closeFn}>
            <CloseIcon />
            <span className="structural">{intl.formatMessage({ id: "close" })}</span>
          </ButtonClose>
        }
        <Constraint alignContent={alignContent} flexBasis={flexBasis} >
          {props.children}
        </Constraint>
      </Wrapper>
    </ModalWrapper>
  )
}
