import React, { useState, useEffect } from "react"
import styled from "styled-components"
import get from "lodash/get"
import { Link, graphql, useStaticQuery } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"

import ShareWidget from "./ShareWidget"
import CookieIcon from "./icons/Cookie"
import AccessibilityIcon from "./icons/Accessibility"

import instagramIcon from "../images/social-instagram.svg"
import facebookIcon from "../images/social-facebook.svg"

const IconLink = styled(Link)`
  img, svg {
    display: inline-block;
    vertical-align: sub;
    width: 1.2rem;
    height: 1.2rem;
  }

  ${props => props.icon && `
    ::before {
      content: "";
      display: inline-block;
      vertical-align: sub;
      height: 1.3em;
      width: 1.3em;
      background: url(${props.icon}) center center no-repeat;
      background-size: contain;
    }
  `}
`

const FooterWrapper = styled.footer`
  background: #fff;
  padding: 1.4rem 1rem;
  font-size: 0.875rem;
  text-align: center;
  }

  .links-wrapper {
    margin-bottom: 0.5rem;

    &.row2 {
      & > * {
        margin-left: 0.3rem;
        margin-right: 0.3rem;
      }
    }
  }

  a {
    text-decoration: none;
    color: #e20000;
    transition: color 0.3s ease;

    &:hover,
    &:focus {
      color: #000;
      text-decoration: none;
    }
  }

  span.divider {
    font-size: 1rem;
  }

  div.social-media-wrapper {
    margin-bottom: 1rem;

    > a {
      display: inline-block;
      margin: 0 0.25rem;
      vertical-align: top;

    > img {
      display: block;
      width: 2.2rem;
    }
  }
`

const StyledShareWidget = styled(ShareWidget)`
  margin: 0 0.25rem;
  width: 2.2rem;
  height: 2.2rem;

  a, button span { color: #333; }
`

export default function Footer({ room, lang, setLangAttr, email, title, ...props }) {
  const intl = useIntl()
  const result = useStaticQuery(graphql`
    query {
      aboutPageSv: pagesYaml(lang: { eq: "sv" }, _id: { eq: "/om-narkoswebben" }) {
        path
        title
      }
      contactPageSv: pagesYaml(lang: { eq: "sv" }, _id: { eq: "/kontakt" }) {
        path
        title
      }
      a11yPageSv: pagesYaml(lang: { eq: "sv" }, _id: { eq: "/tillganglighet" }) {
        path
        title
      }
      cookiesPageSv: pagesYaml(lang: { eq: "sv" }, _id: { eq: "/cookies-och-dataskydd" }) {
        path
        title
      }
      aboutPageEn: pagesYaml(lang: { eq: "en" }, _id: { eq: "/om-narkoswebben" }) {
        path
        title
      }
      contactPageEn: pagesYaml(lang: { eq: "en" }, _id: { eq: "/kontakt" }) {
        path
        title
      }
      a11yPageEn: pagesYaml(lang: { eq: "en" }, _id: { eq: "/tillganglighet" }) {
        path
        title
      }
      cookiesPageEn: pagesYaml(lang: { eq: "en" }, _id: { eq: "/cookies-och-dataskydd" }) {
        path
        title
      }
    }
  `)

  const [pathname, setPathname] = useState("")
  const getTranslatedPage = (page) => lang === "sv" ? result[`${page}Sv`] : result[`${page}En`]
  const getPageLink = (page) => `/${lang}/${get(page, "path", "").toLowerCase().trim()}`
  const translatedAboutPage = getTranslatedPage("aboutPage")
  const aboutLink = getPageLink(translatedAboutPage)
  const translatedContactPage = getTranslatedPage("contactPage")
  const contactLink = getPageLink(translatedContactPage)
  const translatedA11yPage = getTranslatedPage("a11yPage")
  const a11yLink = getPageLink(translatedA11yPage)
  const translatedCookiesPage = getTranslatedPage("cookiesPage")
  const cookiesLink = getPageLink(translatedCookiesPage)

  useEffect(() => {
    const path = window.location.pathname
    setPathname(path.endsWith("/") ? path.slice(0, -1) : path)
  }, [])

  return (
    <FooterWrapper {...(setLangAttr ? { lang } : {})} room={room} {...props}>
      <div className="social-media-wrapper">
        <a href={intl.formatMessage({ id: "instagramLink" })}>
          <img src={instagramIcon} alt="Instagram" />
        </a>
        <a href={intl.formatMessage({ id: "facebookLink" })}>
          <img src={facebookIcon} alt="Facebook" />
        </a>
        <StyledShareWidget />
      </div>
      <div className="links-wrapper row1">
        {translatedAboutPage &&
          <Link
            to={aboutLink}
            {...(pathname === aboutLink ? { "aria-current": "page" } : {})}
          >
            {translatedAboutPage.title}
          </Link>
        }
        {translatedAboutPage && translatedContactPage && <span className="divider"> | </span>}
        {translatedContactPage &&
          <Link
            to={contactLink}
            {...(pathname === contactLink ? { "aria-current": "page" } : {})}
          >
              {translatedContactPage.title}
          </Link>
        }
      </div>
      <div className="links-wrapper row2">
        {translatedA11yPage &&
          <IconLink
            to={a11yLink}
            {...(pathname === a11yLink ? { "aria-current": "page" } : {})}
          >
            <AccessibilityIcon /> {translatedA11yPage.title}
          </IconLink>
        }
        {translatedCookiesPage &&
          <IconLink
            to={cookiesLink}
            {...(pathname === cookiesLink ? { "aria-current": "page" } : {})}
          >
            <CookieIcon /> {translatedCookiesPage.title}
          </IconLink>
        }
      </div>
      <div>© {title}</div>
    </FooterWrapper>
  )
}
