import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import CookieLink from "./CookieLink"
import CookieIcon from "./icons/Cookie"

import cookieConfig from "../cookie-config.json"
import { useStore } from "../hooks/store"
import trackEvent from "../utils/track-event"

const Wrapper = styled.div`
  max-width: 23.4375rem;
  margin: 0 auto 1.5rem;
  padding: 0 1rem;
`

const Inner = styled.div`
  padding: 1.875rem 1rem;
  background: white;
  box-shadow: 0 1.25rem 1.25rem rgba(102, 102, 102, 0.25);
  border-radius: 0.625rem;
  text-align: center;

  p {
    margin: 0 0 1rem;
  }

  svg {
    width: 2.0625rem;
    height: 2.0625rem;
  }
`

const Heading = styled.h2`
  margin: 0.5625rem 0 1.4375rem;
  font: 1.25rem/1.9375rem ${props => props.theme.headingFont};
  color: black;
`

const ActionArea = styled.div`
  margin-top: 1.5rem;

  p {
    margin: 0 0 1.375rem;
    font-family: ${props => props.theme.headingFont};
    line-height: 1.5625rem;
  }
`

const Button = styled.button`
  padding: 0.3125rem 0.9375rem;
  border: 0;
  border-radius: 9999px;
  color: white;
  background: #C60101;
  font: 0.875rem/1.375rem ${props => props.theme.headingFont};
`

export default function CookiesDisabledMessage({ lang, provider, ...props }) {
  const intl = useIntl()
  const [store, updateStore] = useStore()
  const { allTextsYaml } = useStaticQuery(graphql`
    query {
      allTextsYaml(filter: { _id: { eq: "disabledContent" } }) {
        nodes {
          lang
          text {
            html
          }
        }
      }
    }
  `)

  const handleAccept = () => {
    updateStore((store) => {
      store.cookies[provider] = true
      trackEvent({ category: "Cookie Settings", action: "Button Click", name: `Accept: ${provider}` })
    })
  }

  const providerLabel = get(cookieConfig.cookies.find((x) => x.id === provider), "label")

  const text = get(allTextsYaml.nodes
    .find((x) => x.lang === lang), "text.html", "")
    .replace("{provider}", `<b>${providerLabel}</b>`)

  return (
    <Wrapper {...props}>
      <Inner>
        <CookieIcon />
        <Heading>{intl.formatMessage({ id: "theContentCantBeDisplayed" })} :&#65279;(</Heading>
        <div dangerouslySetInnerHTML={{ __html: text }} />
        <p>{intl.formatMessage({ id: "goTo" })} <CookieLink lang={lang} linkToSettings /> {intl.formatMessage({ id: "cookieSettingsText" })}.</p>
        <ActionArea>
          <p>{intl.formatMessage({ id: "wantToAllowCookies" }, { provider: providerLabel })}</p>
          <Button onClick={handleAccept}>{intl.formatMessage({ id: "okAccept" })}</Button>
        </ActionArea>
      </Inner>
    </Wrapper>
  )
}
