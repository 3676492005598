import React, { useState, useEffect, createRef } from "react"
import styled, { keyframes } from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import ConfirmDialog from "./ConfirmDialog"

import isProd from "../utils/is-prod"
import writeChitChatMessage from "../utils/write-chitchat-message"

import sendIcon from "../images/send-icon.svg"
import closeIcon from "../images/close-plaster.svg"

const reminderAnimateIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-5vh);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`

const Form = styled.form`
  position: relative;
`

const TextInput = styled.input`
  width: calc(100% - 2.375rem);
  margin: 0;
  padding: 0.4375rem 2.625rem 0.4375rem 1rem;
  border: 1px solid ${props => props.theme.roomColors[8].darker};
  border-radius: 9999px;
  font-size: 1rem;
  line-height: 1;
`

const SendButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  padding: 0.4375rem;
  border: 1px solid ${props => props.theme.roomColors[8].darker};
  border-radius: 9999px;
  background: ${props => props.theme.roomColors[8].darker};
  color: white;
  font-size: 0.75rem;
  line-height: 1.28125rem;
  font-weight: 600;
  transition: background 0.2s ease;

  &[disabled] {
    background: #555;
    border-color: #555;
  }

  img {
    display: block;
    margin-right: 0.25rem;
  }
`

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  line-height: 1.5;

  :focus {
    outline: 0;
    filter: none;
    box-shadow: none;
  }

  p {
    margin: 0 1rem 0 0;
  }
`

const TryAgainButton = styled.button`
  padding: 0.6875rem 0.625rem;
  border: 1px solid ${props => props.theme.roomColors[8].darker};
  border-radius: 9999px;
  background: white;
  color: ${props => props.theme.roomColors[8].darker};
  font-size: 0.75rem;
  line-height: 1;
  font-weight: 600;
`

const ReminderPersonalData = styled.span`
  display: ${props => props.active ? "flex" : "none"};
  align-items: center;
  position: absolute;
  bottom: 100%;
  margin-bottom: 0.6rem;
  color: white;
  font-size: 0.9rem;
  padding: 0.375rem 0.625rem 0.375rem 1rem;
  background: ${props => props.theme.roomColors[8].darker};
  border-radius: 0.6rem;
  box-shadow: ${props => props.theme.shadows.flatter};
  animation: ${reminderAnimateIn} 1s forwards;

  ::before {
    content: "";
    display: block;
    position: absolute;
    left: 20%;
    top: 100%;
    margin-top: -1px;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-top: 0.6rem solid ${props => props.theme.roomColors[8].darker};
  }
`

const CloseReminderButton = styled.button`
  margin-left: 0.5rem;
  padding: 0;
  border: 0;
  background: none;

  img {
    display: block;
  }
`

export default function ChitChatForm({ onSendSuccess, lang }) {
  const intl = useIntl()
  const inputRef = createRef()
  const errorRef = createRef()
  const [text, setText] = useState("")
  const [reminderShowing, setReminderShowing] = useState(false)
  const [reminderUserClosed, setReminderUserClosed] = useState(false)
  const [isSending, setIsSending] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [hasSendError, setHasSendError] = useState(false)

  const handleSend = (e) => {
    e.preventDefault()

    if (isProd()) {
      setIsSending(true)

      writeChitChatMessage(text, lang)
        .then(({ ref, data: { message, timestamp, username, color, avatar } }) => {
          setIsSending(false)
          onSendSuccess(ref["@ref"].id, message, timestamp, username, color, avatar)
          setText("")
          setShowConfirm(true)
        })
        .catch((error) => {
          console.log(error)
          setIsSending(false)
          setHasSendError(true)
        })
    } else {
      onSendSuccess(null, text, Date.now(), "Test" + Math.floor(Math.random() * 100), "red", "owl")
      setText("")
      setShowConfirm(true)
    }
  }

  useEffect(() => {
    if (hasSendError) {
      errorRef.current.focus()
    } else {
      inputRef.current.focus()
    }
  }, [hasSendError])

  useEffect(() => {
    if (text !== "" && !reminderShowing && !reminderUserClosed) {
      setReminderShowing(true)
    }
    if (text === "" && reminderShowing) {
      handleCloseReminder()
    }
  }, [text])

  const handleUserCloseReminder = () => {
    setReminderUserClosed(true)
    handleCloseReminder()
  }

  const handleCloseReminder = () => {
    setReminderShowing(false)
  }

  return (
    <>
      {hasSendError ?
        <ErrorMessage ref={errorRef} tabIndex="-1">
          <p>{intl.formatMessage({ id: "errorMessage" })}</p>
          <TryAgainButton type="button" onClick={() => setHasSendError(false)}>
            {intl.formatMessage({ id: "tryAgain" })}
          </TryAgainButton>
        </ErrorMessage>
      :
        <Form onSubmit={handleSend}>
          <TextInput
            ref={inputRef}
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder={`${intl.formatMessage({ id: "askYourQuestionHere" })} ...`}
            disabled={isSending}
            aria-describedby="personal-info-reminder"
          />
          <ReminderPersonalData
            id="personal-info-reminder"
            role="tooltip"
            active={reminderShowing}
          >
            {intl.formatMessage({ id: "chitChatReminder" })}
            <CloseReminderButton type="button" onClick={handleUserCloseReminder}>
              <img
                src={closeIcon}
                alt={intl.formatMessage({ id: "close" })}
              />
            </CloseReminderButton>
          </ReminderPersonalData>
          <SendButton type="submit" disabled={isSending || !text.length}>
            <img src={sendIcon} alt="" />{intl.formatMessage({ id: isSending ? "sending" : "send" })}{isSending ? "..." : ""}
          </SendButton>
        </Form>
      }
      {showConfirm &&
        <ConfirmDialog
          heading={intl.formatMessage({ id: "chitChatConfirmHeading" })}
          message={`${intl.formatMessage({ id: "chitChatConfirmMessage" })}.`}
          confirmButtonLabel={intl.formatMessage({ id: "okClose" })}
          onConfirm={() => setShowConfirm(false)}
        />
      }
    </>
  )
}
