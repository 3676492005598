import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { useStaticQuery, graphql } from "gatsby"
import get from "lodash/get"

import InfoIcon from "./icons/Info"

const Wrapper = styled.div`
  padding: 0.875rem 2rem 1.25rem;
  background: rgba(249, 204, 204, 0.75);
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.1);
`

const Title = styled.h3`
  position: relative;
  margin: 0 0 0.125rem;
  padding-left: 1.5rem;
  font: normal 0.875rem/1.375rem ${props => props.theme.headingFont};
  color: #C80101;
  text-transform: uppercase;
`

const Icon = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
  width: 1.125rem;
  height: 1.125rem;
  margin-right: 0.375rem;
  vertical-align: middle;

  svg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Content = styled.div`
  @media (min-width: 600px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }
`

const Text = styled.div`
  p {
    margin: 0 0 1rem;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: #363636;
  }

  @media (min-width: 600px) {
    p {
      margin: 0;
      padding-right: 1rem;
    }
  }
`

const Button = styled.button`
  flex-shrink: 0;
  padding: 0.3125rem 0.625rem;
  border: 1px solid #666;
  border-radius: 9999px;
  background: none;
  font: 0.875rem/1.375rem ${props => props.theme.headingFont};
  color: #666;
  white-space: nowrap;
`

export default function ReduceMotionInfo({ lang, onClose }) {
  const intl = useIntl()

  const { allTextsYaml } = useStaticQuery(graphql`
    query {
      allTextsYaml(filter: { _id: { eq: "reduceMotionInfoText" } }) {
        nodes {
          lang
          text {
            html
          }
        }
      }
    }
  `)

  const infoText = allTextsYaml.nodes.find((x) => x.lang === lang)

  return (
    <Wrapper>
      <Title><Icon><InfoIcon fill="#e00101" /></Icon> {intl.formatMessage({ id: "motionSetting" })}</Title>
      <Content>
        <Text dangerouslySetInnerHTML={{ __html: get(infoText, "text.html") }} />
        <div><Button onClick={onClose}>{intl.formatMessage({ id: "okCloseInfo" })}</Button></div>
      </Content>
    </Wrapper>
  )
}
