const getSlugFromString = require("./utils/get-slug-from-string")

const getGames = (lang) => [{
  id: "memory-1",
  lsKey: "vildingMemory1",
  type: "memory",
}, {
  id: "memory-2",
  lsKey: "vildingMemory2",
  type: "memory",
}, {
  id: "crossword-1",
  lsKey: "vildingCrossword1",
  title: "crossword",
  number: 1,
  thumb: `/assets/hilding_vilding/HV_Cover_Korsord_1_${lang === "sv" ? "Sve" : "Eng"}.png`,
}, {
  id: "crossword-2",
  lsKey: "vildingCrossword2",
  title: "crossword",
  number: 2,
  thumb: `/assets/hilding_vilding/HV_Cover_Korsord_2_${lang === "sv" ? "Sve" : "Eng"}.png`,
}, {
  id: "word-search",
  lsKey: "vildingWordSearch",
  title: "wordSearch",
  thumb: `/assets/hilding_vilding/HV_Cover_Ordsok_${lang === "sv" ? "Sve" : "Eng"}.png`,
}, {
  id: "quiz-1",
  lsKey: "vildingQuiz1",
  type: "quiz",
}, {
  id: "quiz-2",
  lsKey: "vildingQuiz2",
  type: "quiz",
}, {
  id: "quiz-3",
  lsKey: "vildingQuiz3",
  type: "quiz",
}, {
  id: "quiz-4",
  lsKey: "vildingQuiz4",
  type: "quiz",
}, {
  id: "what-belongs-together",
  lsKey: "vildingWhatBelongsTogether",
  title: "whatBelongsTogether",
  hide: true,
}]

const gameStructure = [
  ["memory-1", "memory-2"],
  ["quiz-1", "quiz-2", "quiz-3", "quiz-4"],
  ["crossword-1", "crossword-2"],
  ["word-search", "what-belongs-together"],
]

function getGame(id, lang) {
  return getGames(lang).find((x) => x.id === id)
}

function getGameData({ id, lang, formatMessage, quizes, memories, includeLink = true }) {
  const { title, number, type, thumb, lsKey, hide } = getGame(id, lang)
  let game

  if (type === "quiz") {
    game = quizes.find((x) => x._id === id)
  } else if (type === "memory") {
    game = memories.find((x) => x._id === id)
  }

  const gameTitle = game ?
    game.title :
    `${formatMessage({ id: title })}${number ? ` ${number}` : ""}`

  return {
    title: gameTitle,
    to: includeLink ? `/${lang}/${type === "quiz" ? "quiz/" : ""}${getSlugFromString(gameTitle)}` : null,
    background: game ? game.thumb : thumb,
    isRound: type === "quiz",
    lsKey,
    hide,
  }
}

module.exports = { getGames, gameStructure, getGame, getGameData }
