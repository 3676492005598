import React, { useEffect, useState } from "react"
import styled from "styled-components"
import Flickity from "react-flickity-component"
import get from "lodash/get"

import getUserAgent from "../utils/get-user-agent"
import flickityA11y from "../utils/flickity-a11y"

import arrowSvg from "../images/arrow-white.svg"

const mediaQueries = [{
  screenWidth: 0,
  items: [{
    type: "square",
    width: 34,
  }, {
    type: "circle",
    width: 34,
  }, {
    type: "rectangle",
    width: 54,
  }],
}, {
  screenWidth: 380,
  items: [{
    type: "square",
    width: 32,
  }],
}, {
  screenWidth: 420,
  items: [{
    type: "rectangle",
    width: 52,
  }, {
    type: "square",
    width: 27.5,
  }],
}, {
  screenWidth: 460,
  items: [{
    type: "rectangle",
    width: 40,
  }, {
    type: "square",
    width: 26,
  }],
}, {
  screenWidth: 520,
  items: [{
    type: "circle",
    width: 24,
  }, {
    type: "square",
    width: 24,
  }],
}, {
  screenWidth: 565,
  items: [{
    type: "rectangle",
    width: 34,
  }, {
    type: "square",
    width: 20.5,
  }],
}, {
  screenWidth: 665,
  items: [{
    type: "rectangle",
    width: 28,
  }, {
    type: "square",
    width: 18.8,
  }],
}, {
  screenWidth: 710,
  items: [{
    type: "square",
    width: 16,
  }],
}, {
  screenWidth: 720,
  items: [{
    type: "circle",
    width: 19,
  }],
}, {
  screenWidth: 800,
  items: [{
    type: "rectangle",
    width: 25,
  }],
}, {
  screenWidth: 895,
  items: [{
    type: "rectangle",
    width: 22,
  }, {
    type: "circle",
    width: 15,
  }],
}, {
  screenWidth: 920,
  items: [{
    type: "square",
    width: "7.8125rem",
    fixed: true,
  }],
}, {
  screenWidth: 1025,
  items: [{
    type: "rectangle",
    width: "12.5rem",
    fixed: true,
  }, {
    type: "circle",
    width: "10rem",
    fixed: true,
  }],
}]

const Wrapper = styled.div`
  position: relative;
  margin-bottom: 0.75rem;

  .item {
    margin-right: ${props => props.isTimeline ? 0 : 1}rem;

    &:last-child {
      margin-right: 0;
    }
  }

  // stupid hack for FF
  @-moz-document url-prefix() {
    .item:last-child {
      margin-right: 1rem;
    }
  }

  #subitem-wrapper:focus {
    outline: 0;
  }
`

const StyledFlickity = styled(Flickity)`
  /*! Flickity v2.1.2
  https://flickity.metafizzy.co
  ---------------------------------------------- */

  position: relative;
  width: 100%;
  margin: auto 0;
  padding: 0.6rem 1rem;
  overflow: hidden;

  &:focus {
    outline: 0;
  }

  .flickity-slider {
    height: 100%;
  }

  .flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  .flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-prev-next-button {
    position: absolute;
    top: 0;
    width: 1.75rem;
    height: 100%;
    background: none;
    border: 0;
    outline: 0;
    padding: 0;

    ::before {
      content: "";
      display: block;
      border: 0.125rem solid white;
      border-radius: 50%;
      width: calc(100% - 0.25rem);
      padding-bottom: calc(100% - 0.25rem);
      background: rgba(0, 0, 0, 0.35) url(${arrowSvg}) no-repeat center;
      background-size: contain;
      box-shadow:
        0 -0.1875rem 0.3125rem rgba(102, 102, 102, 0.3);
    }

    svg {
      display: none;
    }
  }

  .flickity-prev-next-button.previous {
    left: 0.25rem;
    transform: rotate(180deg);
    /* box-shadow: 0 -0.1875rem 0.3125rem rgba(102, 102, 102, 0.3); */
  }

  .flickity-prev-next-button.next {
    right: 0.25rem;
    /* box-shadow: 0 0.1875rem 0.3125rem rgba(102, 102, 102, 0.3); */
  }

  .flickity-prev-next-button:disabled {
    display: none;
  }

  &.has-subitems {
    margin-bottom: 0.875rem;
  }
`

const ScrollContainer = styled.div`
  position: relative;
  width: 100%;
  transition: margin-bottom 0.3s ease;

  ${props => props.hasSubItems && `
    margin-bottom: 11rem;
  `}
`

const ScrollInner = styled.div`
  padding: 1rem 1rem 1.5rem;
  overflow: auto;
  white-space: nowrap;

  .item {
    display: inline-block;
    vertical-align: top;
  }
`

const SubItemWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
`

const Rectangle = styled.div`
  ${mediaQueries.map((mq) => {
    const item = mq.items.find((x) => x.type === "rectangle")

    if (!item) {
      return ""
    }

    return mq.screenWidth ? `
      @media (min-width: ${mq.screenWidth}px) {
        width: ${item.width}${item.fixed ? "" : "%"};
      }
    ` : `
      width: ${item.width}%;
    `
  })}
`

const Circle = styled.div`
  margin-right: 1.875rem;

  ${mediaQueries.map((mq) => {
    const item = mq.items.find((x) => x.type === "circle")

    if (!item) {
      return ""
    }

    return mq.screenWidth ? `
      @media (min-width: ${mq.screenWidth}px) {
        width: ${item.width}${item.fixed ? "" : "%"};
      }
    ` : `
      width: ${item.width}%;
    `
  })}
`

const Square = styled.div`
  ${mediaQueries.map((mq) => {
    const item = mq.items.find((x) => x.type === "square")

    if (!item) {
      return ""
    }

    return mq.screenWidth ? `
      @media (min-width: ${mq.screenWidth}px) {
        width: ${item.width}${item.fixed ? "" : "%"};
      }
    ` : `
      width: ${item.width}%;
    `
  })}
`

const Balloon = styled.div`
  width: 24.8%;
  margin-right: 5.2%;

  @media (min-width: 450px) {
    &.balloon {
      width: 7rem;
      margin-right: 1.4375rem;
    }
  }
`

export default function RoomContentScroller({
  items = [],
  subItems = [],
  activeItemIndex = -1,
  shape = "rectangle",
  subItemShape = "rectangle",
  isTimeline,
  ...props
}) {
  const ua = getUserAgent()
  const [isFlickity, setIsFlickity] = useState(null)
  const flickityOptions = isFlickity ? {
    pageDots: false,
    draggable: false,
    contain: true,
    cellAlign: "left",
    groupCells: true,
    on: {
      ready: flickityA11y,
      change: () => flickityA11y(false),
    },
  } : null
  let Comp
  let SubComp
  switch (shape) {
    case "circle":
      Comp = Circle
      break
    case "balloon":
      Comp = Balloon
      break
    case "square":
      Comp = Square
      break
    default:
      Comp = Rectangle
  }
  switch (subItemShape) {
    case "circle":
      SubComp = Circle
      break
    case "balloon":
      SubComp = Balloon
      break
    case "square":
      SubComp = Square
      break
    default:
      SubComp = Rectangle
  }
  const getLazyBreakpoint = (index) =>
    get(mediaQueries.find((mq) => {
      const item = mq.items.find((i) => i.type === shape)
      return item && ((item.fixed && index > 2) || Math.ceil(100 / item.width) > index)
    }), "screenWidth")
  const hasSubItems = subItems && subItems.length > 0
  const subItemsWithId = hasSubItems ? subItems.map((item, index) => ({ id: index, component: item })) : []
  const subContent = subItemsWithId.map((item, index) => (
    <SubComp key={item.id} className={`item ${subItemShape}`}>
      {typeof item.component === "function" ? item.component({ lazyBp: getLazyBreakpoint(index) }) : item.component}
    </SubComp>
  ))
  const itemsWithId = items.map((item, index) => ({ id: index, component: item }))
  const content = itemsWithId.map((item, index) => (
    <Comp key={item.id} className={`item ${shape}`}>
      {typeof item.component === "function" ? item.component({ lazyBp: getLazyBreakpoint(index) }) : item.component}
      {!isFlickity && hasSubItems && activeItemIndex === index &&
        <SubItemWrapper tabIndex="-1" id="subitem-wrapper">
          <ScrollContainer style={{ overflow: "hidden" }}>
            <ScrollInner>
              {subContent}
            </ScrollInner>
          </ScrollContainer>
        </SubItemWrapper>
      }
    </Comp>
  ))

  useEffect(() => {
    if (typeof window !== "undefined" && isFlickity === null) {
      setIsFlickity(
        (!ua.isMobile && ua.os.name !== "Mac OS") ||
        (ua.os.name === "iOS" && Number(ua.os.version.split(".")[0]) <= 12)
      )
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      if (activeItemIndex > -1) {
        const subItemWrapper = isFlickity ?
          document.querySelector("#subitem-wrapper .flickity-enabled") :
          document.getElementById("subitem-wrapper")

        if (subItemWrapper) {
          subItemWrapper.focus()
        }
      }
    })
  }, [activeItemIndex])

  return (
    <Wrapper
      isFreeScroll={!isFlickity}
      isTimeline={isTimeline}
      {...props}
      className={`${props.className || ""} room-content-scroller`} // Used for lazy loading
    >
      {isFlickity ?
        <div>
          <StyledFlickity options={flickityOptions} className={hasSubItems ? "has-subitems" : ""}>
            {content}
          </StyledFlickity>
          {hasSubItems &&
            <div id="subitem-wrapper">
              <StyledFlickity options={flickityOptions}>
                {subContent}
              </StyledFlickity>
            </div>
          }
        </div>
      :
        <ScrollContainer hasSubItems={hasSubItems}>
          <ScrollInner>
            {content}
          </ScrollInner>
        </ScrollContainer>
      }
    </Wrapper>
  )
}
