import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import useIsClient from "../hooks/isClient"

const Button = styled.button`
  border: 1px solid #d2d2d2;
  border-radius: 9999px;
  background-color: #ebebeb;
  display: flex;
  width: 6.25rem;
  padding: 0.1875rem 0;
  position: relative;
  cursor: pointer;
  font-family: ${props => props.theme.headingFont};
  font-size: 0.75rem;
  text-transform: uppercase;
  line-height: 1.6875rem;
  transition: opacity 0.3s ease;

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`

const Indicator = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 50%;
  height: 100%;
  padding: 0.125rem;
  transition: all 125ms;
  transform: ${props => (props.active ? "translateX(0)" : "translateX(100%)")};
  pointer-events: none;
`

const IndicatorInner = styled.span`
  display: block;
  border-radius: 9999px;
  background-color: ${props =>
    props.active
      ? props.theme.activeControlColor
      : props.theme.inactiveControlBgColor};
  width: 100%;
  height: 100%;
  transition: all 125ms;
`

const Value = styled.span`
  display: block;
  width: 50%;
  color: ${props => (props.active ? "white" : props.theme.inactiveControlColor)};
  text-align: center;
  position: relative;
  pointer-events: none;
  user-select: none;
  transition: all 125ms;
`

export default function ToggleControl({
  buttonID,
  value,
  onChange,
  name,
  ...props
}) {
  const intl = useIntl()
  const { key } = useIsClient()
  const [isToggled, setIsToggled] = useState(false)

  function handleKey(event) {
    switch (event.key) {
      case "Space":
      case " ":
        onChange(!isToggled)
        event.preventDefault()
        break
    }
  }

  useEffect(() => {
    setIsToggled(value)
  }, [value])

  return (
    <Button
      key={`easy_read_toggle_${key}`}
      role="switch"
      aria-checked={String(!!isToggled)}
      id={buttonID}
      onClick={() => {
        onChange(!isToggled)
      }}
      onKeyDown={handleKey}
      {...props}
    >
      <Indicator active={isToggled}>
        <IndicatorInner active={isToggled}></IndicatorInner>
      </Indicator>
      <Value active={isToggled}>
        {intl.formatMessage({ id: "toggleIsOn", defaultMessage: "På" })}
      </Value>
      <Value active={!isToggled}>
        {intl.formatMessage({ id: "toggleIsOff", defaultMessage: "Av" })}
      </Value>
    </Button>
  )
}
