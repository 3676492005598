import React, { useEffect, useState, useRef } from "react"

export default function ChitChatImportSvg({ avatar, ...props }) {
  const [iconModule, setIconModule] = useState(null)
  const componentIsMounted = useRef(true)

  useEffect(() => {
    import(`../images/chitchat/avatar_${avatar}.inline.svg`)
      .then((module) => {
        if (componentIsMounted.current) {
          setIconModule(module)
        }
      })
      .catch((error) => {
        console.error(`Icon with name: ${avatar} not found!`, error)
      })
  }, [name])

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  const renderIcon = () => {
    if (!iconModule) return null

    const Component = iconModule.default

    return <Component {...props} />
  }

  return renderIcon()
}
