import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const buttonStyle = `
  position: relative;
  width: 2.625rem;
  height: 2.625rem;
  margin: 0;
  font-size: 0;
  line-height: 0;
  white-space: nowrap;
  appearance: none;
  text-decoration: none;
  z-index:99;

  > *:nth-child(2) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
`

const Button = styled.button`
  ${buttonStyle}
  padding: 0;
  background: transparent;
  border: 0;
  border-radius: 0;
`

const StyledLink = styled(Link)`
  ${buttonStyle}
  display: inline-block;
`

const Gradient = (props) => {
  let grad = props.gradient
  let stops = null
  /*
  const ns = "http://www.w3.org/2000/svg"
  let g = document.createElementNS(ns, "linearGradient")

  g.id = "gradient"
  g.setAttribute("x1", "40");
  g.setAttribute("x2", "40");
  g.setAttribute("y2", "80");
  g.setAttribute("gradientUnits", "userSpaceOnUse")

  let a = new Array()

  if(typeof grad === 'object') {
    if(!Array.isArray(grad)) {
      grad = Object.values(grad)
    }
    grad.forEach((val,idx) => {
       //let gstop = document.createElementNS(ns, 'stop')
       //gstop.setAttribute('stop-color',val[0])
       //gstop.setAttribute('offset', val[1])

       // g.appendChild(gstop)
       a.push(`<stop offset="${val[1]}" stop-color="${val[0]}" />`)
    })
  }
  */

  if (typeof grad === 'object') {
    if (!Array.isArray(grad)) {
      grad = Object.values(grad)
    }
    stops = grad.map((name, index) => {
      return <stop offset={name[1]} stopColor={name[0]} key={index} />
    })
  }

  return (
    <linearGradient id={props.id} x1="40" x2="40" y2="80" gradientUnits="userSpaceOnUse">
      {stops}
    </linearGradient>
  )
}

const Bg = (props) => {
  const id = props.className.replace(/[^\w]/gi, "")
  return (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 80 80">
      <defs>
        <Gradient gradient={props.gradient} id={id} />
      </defs>
      <circle id="buttonFrame" cx="40" cy="40" r="40" fill={`url(#${id})`} />
      <circle id="buttonInner" cx="40" cy="40" r="35" fill={props.basecolor} />
    </svg>
  )
}


const ActionButtonSvg = ({ children, ...props }) => {
  const Wrapper = props.to ? StyledLink : Button
  return (
    <Wrapper {...props}>
      <Bg {...props} />
      {children}
    </Wrapper>
  )
}

export default ActionButtonSvg
