const defaultLanguage = "sv"

const languages = {
  ar: { name: "العربية", flag: "SA", rtl: true }, // Arabic
  bs: { name: "Bosanski", flag: "BA" }, // Bosnian
  da: { name: "Dansk", flag: "DK" }, // Danish
  prs: { name: "دری", flag: "AF", rtl: true }, // Dari
  en: { name: "English", flag: "GB", ui: true }, // English
  et: { name: "Eesti keel", flag: "EE" }, // Estonian
  fi: { name: "Suomen kieli", flag: "FI" }, // Finnish
  fr: { name: "Français", flag: "FR" }, // French
  el: { name: "Ελληνικά", flag: "GR" }, // Greek
  hi: { name: "हिन्दी", flag: "IN" }, // Hindi
  nl: { name: "Nederlands", flag: "NL" }, // Dutch
  is: { name: "Íslenska", flag: "IS" }, // Icelandic
  it: { name: "Italiano", flag: "IT" }, // Italian
  ja: { name: "Nihongo", flag: "JP" }, // Japanese
  zh: { name: "中文", flag: "CN" }, // Chinese
  ku: { name: "کوردی (سۆرانی)", flag: "IQ", rtl: true }, // Kurdish
  no: { name: "Norsk", flag: "NO" }, // Norwegian
  ps: { name: "پښتو", flag: "AF", rtl: true }, // Pashto
  fa: { name: "فارسی", flag: "IR", rtl: true }, // Persian
  pl: { name: "Polski", flag: "PL" }, // Polish
  pt: { name: "Português", flag: "PT" }, // Portuguese
  ru: { name: "Русский", flag: "RU" }, // Russian
  ti: { name: "ትግርኛ", flag: "TI" }, // Tigrinya
  tr: { name: "Türkçe", flag: "TR" }, // Turkish
  de: { name: "Deutsch", flag: "DE" }, // German
  hu: { name: "Magyar", flag: "HU" }, // Hungarian
  sr: { name: "Srpski", flag: "RS" }, // Serbian
  so: { name: "Somali", flag: "SO" }, // Somali
  sv: { name: "Svenska", flag: "SE", ui: true }, // Swedish
  es: { name: "Español", flag: "ES" }, // Spanish
  sw: { name: "Kiswahili", flag: "KE,TZ" }, // Swahili
  th: { name: "ไทย", flag: "TH" }, // Thai
}

module.exports = {
  languages,
  defaultLanguage,
  isUiLanguage(lang) {
    return languages[lang] && languages[lang].ui
  },
  getLanguages(ui) {
    const langs = ui ?
      Object.keys(languages).reduce((acc, curr) => (
        languages[curr].ui ? Object.assign({}, acc, { [curr]: languages[curr] }) : acc
      ), {})
      :
      languages
    const names = Object.keys(langs).reduce((acc, curr) => (acc.concat(langs[curr].name)), [])
    names.sort((a, b) => a.localeCompare(b))
    return names.reduce((acc, curr) => {
      const key = Object.keys(langs).find((lang) => langs[lang].name === curr)
      return Object.assign({}, acc, { [key] : langs[key] })
    }, {})
  },
  getLanguageName(lang) {
    return languages[lang] && languages[lang].name
  },
  getLanguageFlag(lang) {
    return languages[lang] && languages[lang].flag
  },
  isRTL(lang) {
    return !!languages[lang] && languages[lang].rtl
  },
}
