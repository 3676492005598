import React from "react"
import styled from "styled-components"
import get from "lodash/get"

import RoomContentScroller from "../components/RoomContentScroller"
import MediaThumbnail from "../components/MediaThumbnail"

import getSlugFromString from "../utils/get-slug-from-string"

const Title = styled.span`
  position: absolute;
  left: 0;
  bottom: 0.4rem;
  z-index: 2;
  width: 100%;
  padding: 0.25rem 0.5rem;
  font: bold 1.2rem/1.1 ${props => props.theme.headingFont}, sans-serif;
  color: white;
  z-index: 10;
`

const VoSection = ({ lang, items }) => {
  return (
    <RoomContentScroller
      items={items.map(({ title, images }) => {
        const thumb = get((images || []).find((img) => img.isThumbnail), "image", null) || get(images[3], "image", null) || get(images[2], "image", null) || get(images[1], "image")

        return ({ lazyBp }) => (
          <MediaThumbnail
            title={title}
            to={`/${lang}/${getSlugFromString(title)}`}
            background={encodeURI(thumb)}
            specialType="vo"
            lazyBp={lazyBp}
          >
            <Title>{title}</Title>
          </MediaThumbnail>
        )
      })}
    />
  )
}

export default VoSection
