import React, { useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import throttle from "lodash/throttle"

import NwHelmet from "./NwHelmet"
import ActionButton from "./ActionButton"
import SettingsIcon from "./icons/Settings"
import LogoLink from "./LogoLink"
import ActiveModal from "./modals/ActiveModal"
import TryggwebbButton from "./TryggwebbButton"
import HamburgerIcon from "./icons/Hamburger"
import Footer from "./Footer"
import BrowserUpdateMessage from "./BrowserUpdateMessage"
import CookieBanner from "./CookieBanner"

import { useModal } from "../hooks/modal"
import { PageMetadataProvider } from "../pageMetadata"

import roomBeamLands50 from "../images/room-beam-lands-50.svg"
import roomBeamPortr50 from "../images/room-beam-portr-50.svg"
import roomBeamLands70 from "../images/room-beam-lands-70.svg"
import roomBeamPortr70 from "../images/room-beam-portr-70.svg"
import roomBlock1 from "../images/room-block-flat-1.svg"
import roomBlock2 from "../images/room-block-flat-2.svg"
import roomBlock3 from "../images/room-block-flat-3.svg"
import roomBlock4 from "../images/room-block-flat-4.svg"
import roomBlock5 from "../images/room-block-flat-5.svg"
import roomBlock6 from "../images/room-block-flat-6.svg"
import roomBlock7 from "../images/room-block-flat-7.svg"
import roomBlock8 from "../images/room-block-flat-8.svg"
import hamburger from "../images/room-menu-hamburger.svg"

const roomBlocks = {
  "1": roomBlock1,
  "2": roomBlock2,
  "3": roomBlock3,
  "4": roomBlock4,
  "5": roomBlock5,
  "6": roomBlock6,
  "7": roomBlock7,
  "8": roomBlock8,
}

const roomColors = {
  "1": "255, 180, 180",
  "2": "255, 224, 102",
  "3": "170, 210, 153",
  "4": "255, 200, 130",
  "5": "102, 204, 210",
  "6": "132, 201, 240",
  "7": "222, 204, 164",
  "8": "200, 180, 220",
}

const roomGradient = c => `linear-gradient(
  0deg,
  rgba(${c}, 0.3) 0%,
  rgba(${c}, 0.65) 75px,
  rgba(${c}, 0.3) 75px,
  rgba(${c}, 1) 100%
)`

const Bg = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &.room-1 {
    background-image: ${roomGradient(roomColors["1"])};
  }

  &.room-2 {
    background-image: ${roomGradient(roomColors["2"])};
  }

  &.room-3 {
    background-image: ${roomGradient(roomColors["3"])};
  }

  &.room-4 {
    background-image: ${roomGradient(roomColors["4"])};
  }

  &.room-5 {
    background-image: ${roomGradient(roomColors["5"])};
  }

  &.room-6 {
    background-image: ${roomGradient(roomColors["6"])};
  }

  &.room-7 {
    background-image: ${roomGradient(roomColors["7"])};
  }

  &.room-8 {
    background-image: ${roomGradient(roomColors["8"])};
  }
`

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  height: 3.5rem;
  width: 100%;
  padding-top: env(safe-area-inset-top);
  padding-right: env(safe-area-inset-right);
  padding-left: env(safe-area-inset-left);

  background-image: linear-gradient(
    0deg,
    rgb(226, 0, 0) 0%,
    rgb(184, 0, 0) 100%
  );

  @media screen and (max-width: 320px) {
    height: 2.8rem;
  }

  .room-description {
    position: absolute;
    top: 0;
    right: 0.75rem;
    display: flex;
    align-items: center;
    padding-left: calc(50% + .6rem);
  }

  .room-description__title {
    font-size: 1rem;
    color: #fff;
    font-family: ${props => props.theme.headingFont};
    margin-top: 0;
    margin-right: 0.5rem;
    margin-left: 0;
    text-align: right;
    font-weight: normal;
    line-height: 0.875;

    @media (max-width: 400px) {
      font-size: 0.9rem;
    }

    @media (max-width: 375px) {
      font-size: 0.8rem;
    }

    @media (max-width: 319px) {
      display: none;
    }
  }

  .room-block {
    display: block;
    position: relative;
  }

  .block {
    display: block;
    width: 54px;
    height: 54px;
    margin-top: 0.6875rem;
    position: relative;
    padding: 0;
    border: 0;
    background: none;

    :after {
      content: "";
      background: url(${hamburger}) no-repeat center center;
      background-size: contain;
      display: block;
      position: absolute;
      left: 65%;
      bottom: 65%;
      width: 40%;
      height: 40%;
    }

    @media screen and (max-width: 320px) {
      width: 40px;
      height: 40px;

      img {max-width: 100%;}
    }
  }

  .button--settings {
    position: fixed;
    right: 1rem;
    bottom: 1rem;
  }
`

const StyledLogoLink = styled(LogoLink)`
  position: absolute;
  z-index: 10;
  margin-left: 0.6rem;
  max-width: 50%;
  bottom: -.85rem;

  @media (max-width: 375px) {
    &.sv {max-width: calc(50% - 2rem);}
  }

  @media (max-width: 319px) {
    &.sv {max-width: 8rem;}
    &.en {max-width: 11rem;}
  }
`

const Content = styled.div`
  position: relative;
  top: 3.5rem;
  bottom: 0rem;
  left: 0;
  z-index: 1;
  width: 100%;
  min-height: 95vh;
  overflow: auto;
  padding: 2rem 0 2.5rem;

  // stupid hack for FF
  @-moz-document url-prefix() {
    bottom: 2rem;
  }

  @media (max-width: 320px) {
    top: 2.8rem;
  }

  &::before {
    content: "";
    position: fixed;
    top: 3.5rem;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    background-repeat: no-repeat;
    background-position: top center;
    background-size: contain;
  }

  &::before {
    @media (orientation: portrait) {
      width: 10rem;
      height: 3.745rem;
      background-image: url(${roomBeamPortr50});
    }

    @media (orientation: landscape) {
      width: 13.75rem;
      height: 3rem;
      background-image: url(${roomBeamLands50});
    }

    @media (max-width: 320px) {
      top: 2.8rem;
    }
  }

  &.room-2::before {
    @media (orientation: portrait) {
      background-image: url(${roomBeamPortr70});
    }

    @media (orientation: landscape) {
      background-image: url(${roomBeamLands70});
    }
  }

  &::after {
    content: "";
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 60px;
  }

  &.room-1::after {
    background-image: linear-gradient(
      360deg,
      #FFE8E9 0%,
      #FFDFDF 50%,
      rgba(255, 207, 207, 0) 100%
    );
  }

  &.room-2::after {
    background-image: linear-gradient(
      to bottom,
      rgba(254, 236, 168, 0) 0%,
      rgba(255, 241, 186, 1) 50%,
      rgba(255, 245, 206, 1) 100%
    );
  }

  &.room-3::after {
    background-image: linear-gradient(
      to bottom,
      rgba(207, 230, 197, 0) 0%,
      rgba(216, 234, 207, 1) 50%,
      rgba(229, 241, 223, 1) 100%
    );
  }

  &.room-4::after {
    background-image: linear-gradient(
      to bottom,
      rgba(255, 225, 185, 0) 0%,
      rgba(255, 230, 197, 1) 50%,
      rgba(255, 237, 214, 1) 100%
    );
  }

  &.room-6::after {
    background-image: linear-gradient(
      to bottom,
      rgba(184, 223, 247, 0) 0%,
      rgba(198, 229, 248, 1) 50%,
      rgba(217, 238, 249, 1) 100%
    );
  }

  &.room-7::after {
    background-image: linear-gradient(
      to bottom,
      rgba(236, 226, 203, 0) 0%,
      rgba(239, 231, 212, 1) 50%,
      rgba(239, 231, 212, 1) 100%
    );
  }

  &.room-8::after {
    background-image: linear-gradient(
      to bottom,
      rgba(223, 211, 235, 0) 0%,
      rgba(231, 222, 240, 1) 50%,
      rgba(237, 231, 244, 1) 100%
    );
  }
`

const StyledFooter = styled(Footer)`
  position: relative;
  font-size: 0.9rem;
  min-height: 100px;
  width: 100%;
  text-align: center;
  color: black;
  margin: auto;
  top: 4rem;
  padding: 2rem 1rem;
  background: white;

  ::after {
    content: "";
    display: block;
    width: 100%;
    height: 3rem;
  }

  ${props => props.room === 5 && `
    z-index: 2;
  `}

  ${props => props.room === 6 && `
    padding-bottom: 6rem;
  `}
`

export default function RoomMock({ lang, path, room, title, shareImage, ciceron, ciceronData, translations, ...props }) {
  const intl = useIntl()
  const { openModal } = useModal()
  const pageMetadata = { translations }

  useEffect(() => {
    if (window.roomScrollPos && window.roomScrollPos.autoScroll && window.roomScrollPos.top) {
      const scrollTop = window.roomScrollPos.top
      window.roomScrollPos.autoScroll = false
      setTimeout(() => {
        window.scroll(0, scrollTop)
      })
      setTimeout(() => {
        if (!window.scrollY) {
          window.scroll(0, scrollTop)
        }
      }, 100)
    } else {
      window.roomScrollPos = { room, top: 0 }
    }
    const handleScroll = throttle(() => {
      window.roomScrollPos.top = window.scrollY
    }, 250)
    window.addEventListener("scroll", handleScroll, false)
    return () => window.removeEventListener("scroll", handleScroll, false)
  }, [])

  return (
    <PageMetadataProvider value={pageMetadata}>
      <div {...props}>
        <NwHelmet lang={lang} path={path} title={title} image={shareImage} desc={ciceronData.text} />
        <Bg className={`room-${room}`} />
        <Header className={`header ${lang}`}>
          <StyledLogoLink lang={lang} room={room} />
          {title &&
            <div className="room-description">
              <h1 className="room-description__title">{title}</h1>
              <div className="room-block">
                <button className="block" onClick={(e) => openModal(e, "roomMenu", { lang, room })}>
                  <span className="structural">{intl.formatMessage({ id: "showMenu" })}</span>
                  <img src={roomBlocks[room]} role="presentation" />
                </button>
              </div>
            </div>
          }
          <ActionButton
            onClick={(e) => { openModal(e, "settings", { lang }) }}
            className="button--settings"
          >
            <SettingsIcon />
            {intl.formatMessage({ id: "showSettings" })}
          </ActionButton>
          {ciceron || <TryggwebbButton lang={lang} room={`room-${room}`} data={ciceronData} />}
        </Header>
        <Content className={`room-${room}`}>
          {props.children}
        </Content>
        <ActiveModal />
        <StyledFooter
          lang={lang}
          title={intl.formatMessage({ id: "siteTitle" })}
          email={intl.formatMessage({ id: "emailAddress" })}
          room={room}
        />
      </div>
      <BrowserUpdateMessage />
      <CookieBanner lang={lang} />
    </PageMetadataProvider>
  )
}
