import React, { useEffect } from "react"
import { useModal } from "../../hooks/modal"
import * as modals from "."

export default function ActiveModal({ ...props }) {
  const { activeModal, activeModalProps, closeModal } = useModal()
  const ModalComponent = activeModal && modals[activeModal]

  useEffect(() => {
    window.addEventListener("popstate", closeModal)
    return () => window.removeEventListener("popstate", closeModal)
  }, [])

  if (!ModalComponent) {
    return null
  }

  return <ModalComponent {...props} {...activeModalProps} />
}
