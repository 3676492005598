import React, { useEffect, useState, createRef, useRef } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import { Link } from "gatsby"

import podCover from "./../images/podcast/cover-overlay.png"
import vlogCoverEn from "./../images/vlog/vlog_cover_en.png"
import vlogCoverSe from "./../images/vlog/vlog_cover_se.png"
import voLogo from "../images/vo-logo.svg"

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 0;
  padding-top: ${props => (props.isSquare || props.isRound) ? 100 : 62.5}%;
  background-color: ${props => props.backgroundColor || "white"};
  flex-shrink: 0;
  will-change: transform;
  transition: transform .3s ease-in-out, background-color .3s ease-in-out;

  ${props => props.hasAction && `
    :hover {
      transform: translateY(-0.1875rem);
    }
  `}

  &,
  > * {
    border-radius: 0.3125rem;

    ${props => props.isRound && `
      border-radius: 50%;
    `}
  }

  button {
    border: 0;
    background: transparent;
  }

  > a,
  > button,
  > span {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    padding: 0;
    font-size: 100%;
    white-space: normal;
    text-align: center;
    ${props => props.border && `
      border: 0.3125rem solid white !important;
    `}
    ${props => props.isRound && `
      border-radius: 50%;
    `}
  }

  > a,
  > button {
    display: block;
    will-change: transform, box-shadow;
    box-shadow: ${props => props.isRound && props.border ? `
      ${props.theme.shadows.flat};
    ` : `
      ${props.theme.shadows.flat},
      ${props.theme.shadows.inset};
    `}

    transition:
      background-color 0.3s ease-in-out,
      box-shadow .3s ease-in-out;

    :hover {
      box-shadow: ${props => props.theme.shadows.high};
    }
  }

  > span {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    color: black;
    text-decoration: none;
    box-shadow: ${props => props.theme.shadows.inset};
  }

  ${props => props.backgroundImage && `
    > * {
      background: url(${props.backgroundImage}?nf_resize=fit&w=400) no-repeat center center;
      background-size: cover;
    }
  `}

  .title.is-visible {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    color: black;
  }

  &.podcast > a {
    overflow: hidden;

    ::before {
      content: "";
      position: absolute;
      top: -1px;
      left: -1px;
      z-index: 2;
      width: 101%;
      height: 101%;
      background: url(${podCover}) no-repeat right bottom;
      background-size: cover;
    }

    span.title.is-visible {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 63%;
      height: 100%;
      padding: 0.6rem 0.5rem;
      background: rgba(0,0,0,0.6);
      font-family: ${props => props.theme.headingFont};
      line-height: 1.2;
      color: white;
      white-space: initial;
      text-align: left;
      text-shadow: 0px 1px 1px black;

      @media (max-width: 400px) {
        padding: 0.3125rem;
        font: 1rem/1.15 ${props => props.theme.headingFont} ;
      }

      @media (max-width: 370px) {
        font: 0.875rem/1.15 ${props => props.theme.secondaryFont} ;
      }
    }
  }

  &.vlog > * {
    overflow: hidden;

    span.title.is-visible {
      display: inline-block;
      bottom: 1rem;
      top: auto;
      z-index: 2;
      height: auto;
      padding: 0.2em 1rem;
      color: white;
      background: rgba(0,0,0,0.4);
      font-family: ${props => props.theme.secondaryFont};
      text-transform: uppercase;
    }

    &::after,
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }

    &::before {
      z-index: 3;
      background: url(${props => props.lang === "sv" ? vlogCoverSe : vlogCoverEn}) no-repeat right bottom;
      background-size: cover;
    }

    &::after {
      background-image: linear-gradient(rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 30%, rgba(0,0,0,0) 60%, rgba(0,0,0,0.4) 100%);
      z-index: 1;
    }
  }

  &.vo > * {
    overflow: hidden;
    ::before {
      content: url(${voLogo});
      position: absolute;
      top: 0;
      left: 0.8rem;
      width: 20%;
      height: auto;
    }

    ::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 50%;
      background: linear-gradient(to top, rgba(0,40,50,0.6) 0%, rgba(0,40,50,0) 100%);
    }
  }
`

const PictureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`

const Picture = styled.picture`
  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s ease;
  }

  ${props => props.isRound && `
    img {
      border-radius: 50%;
    }
  `}

  ${props => props.lazy && `
    img {
      filter: blur(15px);
    }
  `}
`

const MediaThumbnail = ({
    to,
    href,
    onClick,
    title,
    titleIsVisible = false,
    border,
    isSquare,
    isRound,
    isPressed = null,
    specialType = null,
    background,
    backgroundColor,
    lang,
    lazyBp,
    imageTransform = false,
    children,
    ...props
  }) => {
  const intl = useIntl()
  const [lazy, setLazy] = useState(false)
  const [lazyLoaded, setLazyLoaded] = useState(false)
  const imageRef = createRef()
  const componentIsMounted = useRef(true)

  const getContent = () => (
    children ||
    <span
      className={`title ${background && !titleIsVisible ? "structural" : "is-visible"}`}
    >
      {title}{href && ` (${intl.formatMessage({ id: "externalLink" })})`}
    </span>
  )

  const isSvg = background && background.toLowerCase().endsWith(".svg")
  const isPng = background && background.toLowerCase().endsWith(".png")

  const getImage = (img, type = "jpg", lowRes = false) => {
    if (imageTransform && (isPng || type === "webp")) {
      const array = img.split("/")
      const filenameArray = array[array.length - 1].split(".")
      const filename = filenameArray.slice(0, -1).join(".")

      return `/assets/images/${type}/${lowRes ? "low-res/": ""}${filename}.${type}`
    }

    const isRectangle = !isSquare && !isRound
    const resizeMethod = isRectangle ? "smartcrop" : "fit"
    const w = lowRes ? 20 : 400
    const h = lowRes ? 13 : 250

    return `${img}?nf_resize=${resizeMethod}&w=${w}${isRectangle ? `&h=${h}` : ""}`
  }

  useEffect(() => {
    if (!isSvg && lazyBp && window.innerWidth < lazyBp) {
      if (typeof IntersectionObserver !== "undefined") {
        setLazy(true)

        const lazyLoad = (elements) => {
          elements.forEach((image) => {
            if (image.intersectionRatio > 0) {
              setLazyLoaded(true)
              observer.unobserve(image.target)
            }
          })
        }

        const observer = new IntersectionObserver(lazyLoad, {
          // where in relation to the edge of the parent container we are observing
          root: imageRef.current.closest(".room-content-scroller"),
          rootMargin: "0px",

          // how much of the element needs to have intersected
          // in order to fire our loading function
          threshold: 0.1,
        })

        observer.observe(imageRef.current)
      } else {
        setLazyLoaded(true)
      }
    }
  }, [])

  useEffect(() => {
    return () => {
      componentIsMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (lazyLoaded) {
      imageRef.current.addEventListener("load", () => {
        if (componentIsMounted.current) {
          setLazy(false)
        }
      }, false)
    }
  }, [lazyLoaded])

  return (
    <Wrapper
      {...props}
      isSquare={isSquare}
      isRound={isRound}
      border={border}
      backgroundColor={backgroundColor}
      hasAction={to || href || onClick}
      className={`${props.className || ""} ${specialType || ""}`}
      lang={lang}
    >
      {background &&
        <PictureWrapper>
          <Picture lazy={lazy} isRound={isRound}>
            {!isSvg &&
              <>
                {lazyBp > 0 && !lazyLoaded &&
                  <>
                    {imageTransform &&
                      <>
                        <source media={`(min-width: ${lazyBp}px)`} srcSet={getImage(background, "webp")} type="image/webp" />
                        <source srcSet={getImage(background, "webp", true)} type="image/webp" />
                      </>
                    }
                    <source media={`(min-width: ${lazyBp}px)`} srcSet={getImage(background)} />
                    <source srcSet={getImage(background, "jpg", true)} />
                  </>
                }
                {lazyBp > 0 && lazyLoaded &&
                  <>
                    {imageTransform &&
                      <source srcSet={getImage(background, "webp")} type="image/webp" />
                    }
                    <source srcSet={getImage(background)} />
                  </>
                }
                {imageTransform && !lazyBp &&
                  <source srcSet={getImage(background, "webp")} type="image/webp" />
                }
              </>
            }
            <img
              ref={imageRef}
              src={isSvg ? background : getImage(background)}
              alt=""
              role="none"
            />
          </Picture>
        </PictureWrapper>
      }
      {onClick &&
        <button
          onClick={onClick}
          {...(isPressed !== null ? { ["aria-pressed"]: isPressed } : {})}
        >
          {getContent()}
        </button>
      }
      {to &&
        <Link to={to}>{getContent()}</Link>
      }
      {href &&
        <a href={href}>{getContent()}</a>
      }
      {!onClick && !to && !href && <span />}
    </Wrapper>
  )
}

export default MediaThumbnail
