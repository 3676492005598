import React from "react"
import styled from "styled-components"

const StyledSvg = styled.svg`
  g {
    fill: ${props => props.color};
    transition: fill .3s ease-in-out;
  }

  :hover, :focus {
    g {fill: ${props => props.hoverColor} }
  }
`

export default function ArrowBandaid({dir = "left", color = "white", hoverColor, size, ...props}) {

  const getDirection = (dir) => {
    switch(dir) {
      case "right":
        return(
          <g>
          <path d="M9.14372 6.51963C7.83776 5.21367 7.54234 3.39175 8.4838 2.45028C9.42527 1.50881 11.2471 1.80428 12.5531 3.11024L17.0073 7.56439L13.5979 10.9738L9.14372 6.51963Z" />
          <path d="M12.553 18.8374C11.2471 20.1433 9.42516 20.4387 8.4837 19.4973C7.54223 18.5558 7.83769 16.7339 9.14366 15.428L13.5978 10.9738L17.0072 14.3832L12.553 18.8374Z" />
          <ellipse cx="17.4602" cy="9.1177" rx="0.72922" ry="0.72922" transform="rotate(45 17.4602 9.1177)" />
          <ellipse cx="17.4602" cy="12.7301" rx="0.729218" ry="0.72922" transform="rotate(45 17.4602 12.7301)" />
          <ellipse cx="15.5438" cy="10.9737" rx="0.729219" ry="0.72922" transform="rotate(45 15.5438 10.9737)" />
          <ellipse cx="19.2658" cy="10.9738" rx="0.729219" ry="0.72922" transform="rotate(45 19.2658 10.9738)" />
          </g>
        )
        break
      case "left":
      default:
        return (
          <g>
          <path d="M11.2714 15.4108C12.5773 16.7168 12.8728 18.5387 11.9313 19.4801C10.9898 20.4216 9.16795 20.1261 7.86199 18.8202L3.40784 14.366L6.81723 10.9566L11.2714 15.4108Z" />
          <path d="M7.86202 3.09306C9.16799 1.7871 10.9899 1.49168 11.9314 2.43314C12.8728 3.37461 12.5774 5.19649 11.2714 6.50245L6.81727 10.9566L3.40788 7.54721L7.86202 3.09306Z" />
          <ellipse cx="2.95483" cy="12.8127" rx="0.72922" ry="0.72922" transform="rotate(-135 2.95483 12.8127)" />
          <ellipse cx="2.95483" cy="9.2003" rx="0.729218" ry="0.72922" transform="rotate(-135 2.95483 9.2003)" />
          <ellipse cx="4.87128" cy="10.9568" rx="0.729219" ry="0.72922" transform="rotate(-135 4.87128 10.9568)" />
          <ellipse cx="1.14923" cy="10.9566" rx="0.729219" ry="0.72922" transform="rotate(-135 1.14923 10.9566)" />
          </g>
        )
        break
    }
  }
  return (
    <StyledSvg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 21 22"
      width={size}
      height={size}
      color={color}
      hoverColor={hoverColor}
      fill="currentColor"
      {...props}
    >
      <style>{`
        g { fill: ${color}}
        :hover, :focus {
          g {fill: ${hoverColor} }
        }
      `}
      </style>
      {getDirection(dir)}
    </StyledSvg>
  )
}
