import React, { useState } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import bgImage from "../../images/did-you-know-that-bg.jpg"

import HvModalWrapper from "./HvModalWrapper"
import RoomContentScroller from "../RoomContentScroller"
import MediaThumbnail from "../MediaThumbnail"

const Content = styled.div`
  position: relative;

  b {
    display: block;
    margin-bottom: 1.25rem;
    font: 1.5rem/1 ${props => props.theme.headingFont};
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
`

const StyledRoomContentScroller = styled(RoomContentScroller)`
  position: fixed;
  bottom: 0.25rem;
  left: 0;
  z-index: 2000;
  width: 100%;
`

const StyledMediaThumbnail = styled(MediaThumbnail)`
  > button {
    border: 0.25rem solid white;
    box-shadow: none;

    > span {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: left;
      font-size: 0.875rem;
      transition: color 0.3s ease;
      ${props => props.isPressed && `
        color: white;
      `}

      > span {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 3.4rem;
        padding: 0 0.625rem;
      }
    }
  }
`

export default function DidYouKnowThatModal({ lang, items }) {
  const intl = useIntl()
  const [selectedItemIndex, setSelectedItemIndex] = useState(0)
  const selectedItem = items[selectedItemIndex]

  const itemScroller = (
    <StyledRoomContentScroller
      items={items.map((item, index) => (
        <StyledMediaThumbnail
          onClick={() => setSelectedItemIndex(index)}
          backgroundColor={selectedItemIndex === index ? "black" : "#B5C963"}
          isPressed={selectedItemIndex === index}
        >
          <span><span>{item.intro} {item.text}</span></span>
        </StyledMediaThumbnail>
      ))}
    />
  )

  return (
    <HvModalWrapper
      logo={`/assets/hilding_vilding/HV_Cover_Visste-du-att_${lang !== "sv" ? "Eng" : "Sve"}.png`}
      logoAltText={intl.formatMessage({ id: "didYouKnowThat" })}
      background={`url(${bgImage}) no-repeat center`}
      borderWidth="0.625rem"
      overflowAuto={false}
      scrollReset={selectedItemIndex}
      overlay={<Overlay />}
      subContent={itemScroller}
    >
      <Content>
        <p><b>{selectedItem.intro}</b> {selectedItem.text}</p>
        {selectedItem.image &&
          <img src={selectedItem.image} alt="" role="presentation" />
        }
      </Content>
    </HvModalWrapper>
  )
}
