import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

const EmailLink = styled.a`
  display: block;
  width: 100%;
  height: 0;
  padding: 100% 0 0;
  border: 0;
  border-radius: 50%;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  animation: email-contact-fade-in 0.5s forwards;

  @keyframes email-contact-fade-in {
    0%    { opacity: 0; }
    100%  { opacity: 1; }
  }
`

export default function QuestionPanelEmailLink({ image }) {
  const intl = useIntl()
  return (
    <EmailLink
      href={`mailto:${intl.formatMessage({ id: "emailAddress" })}`}
      style={{ backgroundImage: image ? `url(${image}?nf_resize=fit&w=320)` : "none" }}
    >
      <span className="structural">{intl.formatMessage({ id: "emailPrompt" })}</span>
    </EmailLink>
  )
}
