import React from "react"
import styled from "styled-components"

import HvModalWrapper from "./HvModalWrapper"
import AudioPlayer from "../AudioPlayer"

const Content = styled.div`
  h2 {
    margin-bottom: 1.625rem;
    color: #00489A;
  }

  p {
    font-size: 1rem;
    line-height: 1.8;
    color: #00489A;
  }

  p.smaller {
    font-size: 0.8em;
    margin-top: 2rem;
    line-height: initial;
  }

  @media (min-width: 540px) {
    p {
      font-size: 1rem;
    }
  }
`

const StyledAudioPlayer = styled(AudioPlayer)`
  margin-bottom: 2rem;
`

export default function NwSongModal() {
  return (
    <HvModalWrapper
      logo="/assets/hilding_vilding/Cover_NW-song_Sve.png"
      background="#85CEE4"
    >
      <Content>
        <h2>Narkoswebbsången</h2>
        <StyledAudioPlayer src="/assets/hilding_vilding/nw-song.mp3" color="0,72,154" />
        <p>
          Pappa är inte rädd, nej inte när åskan går<br />
          Inte för lejon alls, och inte för mamma ens<br />
          Fast hon är arg ibland, så blir pappa inte rädd<br />
          Men är det inte konstigt, tänk han blir rädd<br />
          När jag ska åka till sjukhuset för operation<br />
          Tänk vad konstigt va, att en pappa kan bli så rädd<br />
          <br />
          Usch jag ska sövas, vad läskigt tänk om man dör<br />
          Masker och munskydd, apparater är jag rädd för<br />
          Knivar och blod är äckligt, kissa på sig vad förskräckligt<br />
          Det är inte konstigt att man blir rädd<br />
          För att sprutor få, för att till doktorn gå<br />
          Tror jag stannar hemma här, jag vill inte va med om det här<br />
          <br />
          Schysst att det finns nån som har tänkt på hur man känner sig<br />
          Mamma, pappa och så jag vill veta vad som händer mig<br />
          För då blir jag mindre pirrig, morsan hon blir mindre virrig<br />
          På sajten finns vad vi behöver, svar på det vi undrar över<br />
          Bästa medicinen mot rädd - narkoswebb<br />
          Bästa medicinen mot rädd - narkoswebb
        </p>
        <p className="smaller">
          Originaltitel: Vem är inte rädd ibland/Astrid Lindgren/George Riedel<br />
          Ny text: Gunilla Lööf<br />
          Sång: Clara Strand<br />
          Gitarr: Kenneth Berrio-Garcia<br />
          © Använd med tillstånd av Warner/Chappell Music Scandinavia AB
        </p>
      </Content>
    </HvModalWrapper>
  )
}
