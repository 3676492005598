import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"
import get from "lodash/get"

import drCornerSvg from "../images/dr-corner.svg"
import twBottomSliceSvg from "../images/tw_bottom_slice.svg"

import { useModal } from "../hooks/modal"
import { useStore } from "../hooks/store"

const Button = styled.button`
  position: ${props => props.center ? "absolute" : "fixed"};
  bottom: ${props => props.center ? "-1rem" : 0};
  left: 0%;
  display: ${props => props.center ? "inline-block" : "block"};
  margin: 0;
  padding: 0;
  border: 0;
  background: transparent;

  ${props => !props.show && `
    display: none;
  `}

  img {
    display: block;
    width: auto;
    height: ${props => props.center ? 3.5 : 4.6875}rem;
  }


  ${props => props.center && `
    left: 50%;
    transform: translateX(-50%);

    img {margin: 0 auto;}

    @media (min-width: 600px) and (min-height: 300px) {
      img {
        height: 4rem;
      }
    }

    @media (min-width: 660px) and (min-height: 300px) {
      img {
        height: 4.5rem;
      }
    }
  `}
`

const TryggwebbButton = ({ lang, room, data = null, text = null, audiofile = null, position = "corner" }) => {
  const intl = useIntl()
  const { openModal, closeModal } = useModal()
  const [show, setShow] = useState(true)
  const [store, updateStore] = useStore()

  const ciceronText = text ? text : get(data, "text")
  const ciceronSound = audiofile ? audiofile : get(data, "sound")

  const handleClose = () => {
    setShow(true)
    closeModal()

    if (!store.hideCiceron[room]) {
      updateStore((store) => {
        store.hideCiceron[room] = true
      })
    }
  }

  const openTryggwebbModal = (e = null) => {
    openModal(e, "tryggwebb", {
      lang,
      text: ciceronText,
      audiofile: ciceronSound,
      onClose: handleClose,
    })
  }

  const handleClick = (e) => {
    setShow(false)
    openTryggwebbModal(e)
  }

  useEffect(() => {
    if (!store.hideCiceron[room]) {
      setShow(false)
      openTryggwebbModal()
    }
  }, [])

  let image

  switch (position) {
    case "center":
      image = twBottomSliceSvg
      break
    case "corner":
    default:
      image = drCornerSvg
  }

  return (
    <Button
      show={show}
      center={position === "center"}
      onClick={handleClick}
      aria-label={intl.formatMessage({ id: "doctorSafeWeb" })}
    >
      <img src={image} alt="" role="presentation" />
    </Button>
  )
}

export default TryggwebbButton
