import React from "react"
import styled from "styled-components"
import { useIntl } from "gatsby-plugin-intl"

import refreshIcon from "../images/refresh-icon.svg"

const Wrapper = styled.div`
  padding: 4.125rem 1rem 2.5rem;
  color: ${props => props.theme.textColor};
  text-align: center;

  h3 {
    margin: 0;
    font-size: 1.125rem;
    line-height: 1.25;
  }

  p {
    margin: 0;
    font-size: 0.875rem;
  }

  a {
    color: inherit;
  }
`

const TryAgainButton = styled.button`
  position: relative;
  margin: 3.125rem 0;
  padding: 0.375rem 2.25rem 0.375rem 0.5rem;
  border: 1px solid #A064B4;
  border-radius: 0.1875rem;
  font: 1.125rem/1.25 ${props => props.theme.headingFont};
  background: rgba(160, 100, 180, 0.1);

  ::after {
    content: url("${refreshIcon}");
    position: absolute;
    top: 50%;
    right: 0.5rem;
    width: 1.125rem;
    height: 1rem;
    margin-top: -0.5rem;
  }
`

export default function ErrorMessage({ onRetry, ...props }) {
  const intl = useIntl()
  const emailAddress = intl.formatMessage({ id: "emailAddress" })

  return (
    <Wrapper {...props}>
      <h3>{intl.formatMessage({ id: "feedErrorMessage" })}</h3>
      <TryAgainButton onClick={onRetry}>
        {intl.formatMessage({ id: "tryAgain" })}
      </TryAgainButton>
      <p>{intl.formatMessage({ id: "feedErrorMessage2" })} <a href={`mailto:${emailAddress}`}>{emailAddress}</a></p>
    </Wrapper>
  )
}
