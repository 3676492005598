import React from "react"
import styled from "styled-components"

import DownloadIcon from "./icons/Download"

import pdfIcon from "../images/pdf-icon.svg"

const Wrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: space-between;
  min-height: 8rem;
  padding: 0.625rem;
  border: 1px solid white;
  border-radius: 0.3125rem;
  background: rgba(0, 0, 0, 0.2);
  text-decoration: none;
  color: white;
  transition: background .3s ease;

  &:hover,
  &:focus {
    color: white;
    background: rgba(0, 0, 0, 0.3);

    svg {
      background: white;
      border-color: #09519F;
    }

    path {
      fill: #09519F;
    }

    line {
      stroke: #09519F;
    }
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 1rem;
`

const Title = styled.h3`
  margin: 0 0 1.25rem;
  font: 1rem/1.25rem ${props => props.theme.headingFont};
`

const StyledDownloadIcon = styled(DownloadIcon)`
  position: absolute;
  left: 50%;
  bottom: -0.9375rem;
  width: 1.875rem;
  height: 1.875rem;
  margin-left: -0.9375rem;
  border: 1px solid white;
  border-radius: 50%;
  background: #09519F;
  transition: all 0.3s ease;

  path {
    fill: white;
    transition: fill 0.3s ease;
  }

  line {
    stroke: white;
    transition: stroke 0.3s ease;
  }
`

const Description = styled.p`
  margin: 0 0 1.25rem;
  font-size: 0.875rem;
  line-height: 1rem;
`

const MetaData = styled.p`
  display: flex;
  align-items: center;
  margin: auto 0 0;
  padding-left: 1.375rem;
  font-size: 0.75rem;
  line-height: 1.0625rem;
  text-transform: uppercase;
  background: url(${pdfIcon}) no-repeat 0 center;
`

const Image = styled.div`
  flex-shrink: 0;
  width: 35%;
  border-radius: 0.3125rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`

export default function PrintDownload({ title, description, file, filesize, image }) {
  const filetype = file.split(".").slice(-1)[0]

  return (
    <Wrapper href={file}>
      <Column>
        <Title>{title}</Title>
        {description && <Description>{description}</Description>}
        <MetaData>
          {filetype} {filesize && <span>&nbsp;({filesize} MB)</span>}
        </MetaData>
      </Column>
      {image &&
        <Image style={{ backgroundImage: `url(${image}?nf_resize=fit&w=404)` }} />
      }
      <StyledDownloadIcon color="white" />
    </Wrapper>
  )
}
